<template>
    <div v-if="project && !loading">
        <h2 class="title"
            v-if="project.name">Edit {{ project.name }}</h2>
        <h2 class="title"
            v-else>Add Project</h2>
        <div v-if="$route.query.source == 'Revit'"
            class="notification is-warning content">
            <p>
                <strong>It looks like you've arrived here from Revit</strong>
            </p>
            <p>
                Please take a moment to check that all the project fields are correct and up to date.
                Most importantly please set the
                <em>Restriction Level</em> field to the desired setting for your project.
                <br />
            </p>
            <p>Thanks!</p>
        </div>
        <div class="columns">
            <div class="column">
                <FormKit type="form"
                    @submit="save">
                    <div class="columns">
                        <div class="column">
                            <FormKit type="text"
                                label="Name"
                                validation="required"
                                v-model="project.name"></FormKit>
                        </div>
                        <div class="column">
                            <FormKit type="text"
                                label="Number"
                                validation="required"
                                v-model="project.number"></FormKit>
                        </div>
                    </div>
                    <FormKit type="textarea"
                        label="Address"
                        v-model="project.address"></FormKit>
                    <div class="field">
                        <FormKit type="select"
                            label="Restriction level"
                            :options="restrictionLevels"
                            v-model="project.restrictionLevel">
                        </FormKit>
                        <div class="notification my-3 content">
                            <p>
                                If you don't want any model usage events to be recorded and transmitted over the network
                                then set this to <em>Restricted</em>.
                            </p>
                            <p>
                                Otherwise we recommend setting it to <em>Events and model information upload</em> so you
                                can
                                take advantage of the model elements and model audit tools on this site.
                            </p>
                            <p>
                                <em>Events only</em> will record lightweight usage events but not in-depth model
                                information.
                            </p>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Admins</label>
                        <user-multiple-select v-model="project.admins">
                        </user-multiple-select>
                    </div>
                    <FormKit type="textarea"
                        label="Folder paths"
                        v-model="project.folderPaths">
                    </FormKit>
                    <FormKit type="textarea"
                        label="Automation endpoints"
                        help="Restrict automation to only the selected endpoints"
                        v-model="project.automationEndpoints"></FormKit>
                    <div v-if="project.restrictionLevel == 'A'"
                        class="panel">
                        <div class="panel-heading">Audits</div>
                        <div class="panel-block">
                            <FormKit type="number"
                                label="Update frequency (in days)"
                                help="This is the minimum time between automatic audit data updates"
                                v-model="project.modelInformationUpdateFrequency"></FormKit>
                        </div>
                        <div class="panel-block">
                            <FormKit type="select"
                                label="Maximum data weight"
                                :options="{
                                    L: 'Light',
                                    M: 'Medium',
                                    H: 'Heavy',
                                }"
                                help="Determines the type of audit data that will be uploaded"
                                v-model="project.maxWeight"></FormKit>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="panel">
                                <div class="panel-heading">Libraries</div>
                                <div v-for="library in libraries"
                                    class="panel-block">
                                    <label class="checkbox">
                                        <input v-model="library.selected"
                                            type="checkbox" />
                                        {{ library.title }}
                                    </label>
                                </div>
                                <div v-if="selectedLibraries.length"
                                    class="panel-block">
                                    <FormKit type="checkbox"
                                        label="Restrict content access to only the selected content libraries"
                                        v-model="project.restrictContent"></FormKit>
                                </div>
                                <div class="panel-block is-italic">
                                    Choosing one or more content libraries here will pre-select them in
                                    Content Lab.
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="panel">
                                <div class="panel-heading">Content Sets</div>
                                <div v-for="set in project.sets"
                                    class="panel-block is-block">
                                    {{ set.name }}
                                    <a @click="removeSet(set)"
                                        title="Remove set"
                                        class="is-pulled-right">
                                        <span class="icon">
                                            <span class="mdi mdi-close"></span>
                                        </span>
                                    </a>
                                </div>
                                <div class="panel-block">
                                    <div class="field has-addons">
                                        <content-set-select v-model="currentSet"
                                            :show-none="true"
                                            group-by="contentTypeName"></content-set-select>
                                        <template v-if="currentSet">
                                            <div class="control">
                                                <a @click="addSet(currentSet)"
                                                    title="Add set"
                                                    class="button">
                                                    <span class="icon">
                                                        <span class="mdi mdi-check"></span>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="control">
                                                <a @click="currentSet = null"
                                                    title="Clear selection"
                                                    class="button">
                                                    <span class="icon">
                                                        <span class="mdi mdi-close"></span>
                                                    </span>
                                                </a>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <content-filename-format-edit-panel v-model="project.filenameFormats"
                        :library="selectedLibraries[0]"
                        :show-default="false"></content-filename-format-edit-panel>
                </FormKit>
            </div>
        </div>
    </div>
</template>

<script>
import ContentFilenameFormatEditPanel from "@/content/components/ContentFilenameFormatEditPanel";
import ContentSetSelect from "@/content/components/ContentSetSelect";
import http from "@/http";
import { RESTRICTION_LEVELS } from "@/projects/constants";
import UserMultipleSelect from "@/users/components/UserMultipleSelect";
import { FormKit } from "@formkit/vue";
import Project from "../models/Project";

export default {
    components: {
        FormKit,
        UserMultipleSelect,
        ContentFilenameFormatEditPanel,
        ContentSetSelect,
    },
    data() {
        return {
            project: null,
            clients: [],
            errors: {},
            libraries: [],
            loading: true,
            restrictionLevels: RESTRICTION_LEVELS,
            currentSet: null,
        };
    },
    async mounted() {
        if (this.$route.params.id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/projects/${this.$route.params.id}/`)
                .then(resp => resp.json());
            this.project = new Project(data);
            this.project.folderPaths = (this.project.folderPaths || []).join('\r\n');
            this.project.automationEndpoints = (this.project.automationEndpoints || []).join('\r\n');
        } else {
            this.project = new Project();
        }

        await this.$store.dispatch("content/loadLibraryList");
        this.libraries = this.$store.getters["content/getLibraries"].filter(x => x.active);
        this.libraries.forEach(x => {
            x.selected = this.project.contentLibraries.includes(x.code);
        });
        this.loading = false;
    },
    methods: {
        async save() {
            let data = {
                name: this.project.name,
                number: this.project.number,
                restriction_level: this.project.restrictionLevel,
                address: this.project.address,
                model_information_update_frequency:
                    this.project.modelInformationUpdateFrequency,
                max_weight: this.project.maxWeight,
                restrict_content: this.project.restrictContent,
                admins: this.project.admins.map(x => x.id),
                sets: this.project.sets.map(x => x.id),
                filename_formats: this.project.filenameFormats
                    .filter(x => x.selected)
                    .map(x => {
                        return {
                            application: x.application.code,
                            format: x.format,
                        }
                    })
            };

            data.content_libraries = this.selectedLibraries.map(x => x.id);

            if (this.project.folderPaths)
                data.folder_paths = this.project.folderPaths.split('\n');

            if (this.project.automationEndpoints)
                data.automation_endpoints = this.project.automationEndpoints.split('\n');

            if (this.$route.params.id) {
                await http.patch(
                    `${process.env.VUE_APP_API_URL_V1}/projects/${this.$route.params.id}/`,
                    data);

                this.$router.push({
                    name: "project-detail",
                    params: {
                        id: this.$route.params.id,
                    },
                });
            } else {
                let respData = await http.post(`${process.env.VUE_APP_API_URL_V1}/projects/`, data)
                    .then(resp => resp.json());

                if (respData.id)
                    this.$router.push({
                        name: "project-detail",
                        params: {
                            id: respData.id,
                        },
                    });
                else
                    this.errors = respData;
            }
        },
        removeSet(set) {
            this.project.sets = this.project.sets.filter(x => x.id != set.id);
        },
        addSet(set) {
            if (!this.project.sets.map(x => x.id).includes(set.id)) {
                this.project.sets.push(set);
                this.currentSet = null;
            }
        },
    },
    computed: {
        selectedLibraries() {
            return this.libraries.filter(x => x.selected);
        },
    },
    watch: {
        selectedLibraries(val) {
            if (val.length === 0) {
                this.project.restrictContent = false;
            }
        }
    },
};
</script>