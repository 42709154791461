<template>
    <div v-if="type.sub_types.length"
        class="control">
        <div class="select">
            <select v-model="selectedSubType">
                <option :value="null"
                    v-if="showNone">None</option>
                <option :value="null"
                    v-if="showAll">All sub types</option>
                <option v-for="st in type.sub_types">{{ st }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        type: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedSubType: null,
        }
    },
    watch: {
        selectedSubType(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedSubType = val;
        },
    }
}
</script>