<template>
    <div class="panel">
        <div class="panel-heading">
            {{ tasks.length || 'No' }} {{ tasks.length == 1 ? 'Waiting Task' : 'Waiting Tasks' }}
        </div>
        <div class="panel-block is-justify-content-space-between"
            v-for="task in tasks">
            {{ task.description }}
            (created {{ task.created.fromNow() }})
            <span class="is-pulled-right">
                <span class="tag mr-1">
                    {{ task.priorityName }}
                </span>
                <a v-if="task.priority != 1"
                    @click="raisePriority(task)"
                    title="Raise priority of task">
                    <span class="mdi mdi-chevron-up-circle"></span>
                </a>
                <a v-if="task.priority != 3"
                    @click="lowerPriority(task)"
                    title="Lower priority of task">
                    <span class="mdi mdi-chevron-down-circle"></span>
                </a>
            </span>
        </div>
    </div>
</template>

<script>
import http from "@/http";
import Task from "@/bim/models/Task";

export default {
    props: ["content"],
    data() {
        return {
            tasks: [],
        };
    },
    async mounted() {
        let data = await http.get(
            `${process.env.VUE_APP_API_URL_V1}/bim/tasks/?content=${this.content.id}&state=W`)
            .then(resp => resp.json());
        this.tasks = data.results.map(x => new Task(x));
    },
    methods: {
        async raisePriority(task) {
            if (task.priority == 1)
                return;

            let priority = task.priority - 1;
            await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/${task.id}/`, { priority });
            this.tasks.find(x => x.id == task.id).priority = priority;
        },
        async lowerPriority(task) {
            if (task.priority == 3)
                return;

            let priority = task.priority + 1;
            await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/tasks/${task.id}/`, { priority });
            this.tasks.find(x => x.id == task.id).priority = priority;
        },
    }
};
</script>

