<template>
    <div class="control">
        <div class="select">
            <select placeholder="All Offices"
                v-model="selectedOffice">
                <option :value="null"
                    selected>All offices</option>
                    <option v-for="office in offices" :value="office">
                        {{ office.name }} ({{ office.country }})
                    </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        region: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedOffice: null,
        }
    },
    async created() {
        this.selectedOffice = this.modelValue;
        await this.$store.dispatch("core/loadOfficeList");
    },
    computed: {
        offices() {
            let allOffices = this.$store.getters["core/getOffices"];
            return this.region
                ? allOffices.filter(x => x.region === this.region)
                : allOffices;
        },
    },
    watch: {
        selectedOffice(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedOffice = val;
        }
    }
}
</script>