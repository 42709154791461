import BaseData from "@/core/models/BaseData";
import User from "@/users/models/User";
import { CONTENTTYPES } from "../constants";

class ContentSet extends BaseData {
    constructor(data) {
        super(data);

        this.content = this.content || [];

        if (this.userprofile)
            this.userprofile = new User(this.userprofile);

        delete this.user;
    }

    get contentTypeName() {
        return CONTENTTYPES.find(x => x.code == this.contentType)?.title
            || this.contentType
            || "Miscellaneous";
    }
}

export default ContentSet;