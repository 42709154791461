<template>
    <notifications :containerClass="containerClass"
        :notification-types="['MS']"
        :targets="['W']"
        template-name="nav"></notifications>
    <div id="header">
        <div :class="containerClass">
            <sitenav></sitenav>
            <a id="full-width-toggle"
                title="Toggle full width"
                @click="fullWidth = !fullWidth"
                class="is-hidden-mobile">
                <i v-if="!fullWidth"
                    class="mdi mdi-arrow-expand"></i>
                <i v-if="fullWidth"
                    class="mdi mdi-arrow-collapse"></i>
            </a>
        </div>
    </div>
    <message></message>
    <loading></loading>
    <div id="sub-header">
        <div :class="containerClass">
            <router-link :to="{ name: 'page-redirect', query: { reference: $route.path } }"
                class="is-pulled-right p-3 grow-on-hover"
                title="Show help page">
                <span class="icon">
                    <span class="mdi mdi-information-slab-circle mdi-24px"></span>
                </span>
            </router-link>
            <h2 class="title py-2 m-0">{{ title }}</h2>
            <div id="page-nav">
                <breadcrumbs :key="$route.fullPath"></breadcrumbs>
            </div>
        </div>
    </div>
    <div id="content">
        <div :class="containerClass"
            class="mt-5">
            <router-view class="view"
                :key="$route.fullPath"></router-view>
        </div>
    </div>
    <section id="footer">
        <div :class="containerClass">
            <sitefooter></sitefooter>
        </div>
    </section>
</template>

<script>
import Nav from "@/core/components/Nav";
import Footer from "@/core/components/Footer";
import Breadcrumbs from "@/core/components/Breadcrumbs";
import Loading from "./core/components/Loading";
import Message from "./core/components/Message";
import Notifications from "./core/components/Notifications";

export default {
    name: "app",
    components: {
        sitenav: Nav,
        breadcrumbs: Breadcrumbs,
        sitefooter: Footer,
        Loading,
        Message,
        Notifications,
    },
    data: () => {
        return {
            fullWidth: localStorage.fullWidth == "true",
        };
    },
    created() {
        // Remove old tokens 
        localStorage.removeItem('AUTH_TOKEN');
    },
    computed: {
        loading() {
            return this.$store.state.core.loading;
        },
        title() {
            return this.$route.meta.title;
        },
        containerClass() {
            return {
                "container full-width": this.fullWidth,
                container: !this.fullWidth,
                [this.$route.name]: true,
            };
        },
    },
    watch: {
        fullWidth(val) {
            localStorage.fullWidth = val;
        },
    },
};
</script>

<style lang="sass">

$black: #000
$blue1: #231edc
$blue2: #337ab7
$darkGrey: #2a2730
$lightGrey: rgb(243, 243, 243)
$midGrey: #ffffff1c
$white: #fff
$borderColor: #ccc
$defaultFontSize: 14px

body
    font-family: 'Jacobs Chronos', sans-serif
    font-size: $defaultFontSize
    font-weight: 400

    .container
        padding: 0 1em

    .container.full-width
        max-width: 95% !important

    #header
        background: $white

        a
            color: $black

    #sub-header
        background: $lightGrey
        border-top: 1px solid $midGrey
        height: 104px

        a
            color: $black

        h2
            font-size: 32px
            line-height: 1.5em

    #content
        min-height: 1024px
        margin-top: 1rem

        .container
            .view
                margin-top: 1.25rem

                .title
                    font-weight: 500

    #footer
        background: $darkGrey
        text-align: center
        padding: 3em 0em
        margin-top: 4em
        height: 300px
        clear: both

        a
            color: $lightGrey

    #full-width-toggle
        position: fixed
        top: 0
        right: 0
        color: rgba(255, 255, 255, 0.5)
        padding: 10px 18px
        font-size: 18px
        transition: background 0.2s

    ul.nav
        margin-top: 4px

    a
        color: rgb(35, 30, 220)

        &.active
            font-weight: 700
            background: none

    table
        &.detail
            th,td
                padding: 2px

            th
                font-weight: 700
                min-width: 10em


    // Bulma components overrides
    .card
        border-radius: 0
        box-shadow: none

        .card-image
            height: 145px

        .card-content
            padding: 1rem
            font-size: 16px

            h4
                text-transform: uppercase
                color: #999
                font-size: 16px

            div
                //font-size: 23px
                color: #000

        .card-image
            img
                border-radius: 0

        .card-header
            box-shadow: none
            padding: 1.5rem 0 0 1.5rem

        // Index page
        &.index-card
            height: 280px
            border: #999999 1px solid

        // Section index pages
        &.section-index
            border: #999999 1px solid
            height: 160px
            border-top: #000 solid 4px

            .card-header-title
                font-size: 20px
                background: url(squares.png) no-repeat left 2px
                padding: 0 0 0 1.3em

            .card-content
                font-size: 16px

    .box
        border: 1px solid $borderColor
        border-radius: 0
        box-shadow: none
        margin-bottom: 1rem

    .panel
        box-shadow: none
        border-radius: 0
        border-color: $borderColor
        border-width: 1px
        border-style: solid
        font-size: $defaultFontSize

        .panel-heading
            border-radius: 0
            font-weight: 500
            padding: 0.5em 0.75em
            background-color: #ededed
            color: #363636

            a
                font-size: var(--bulma-size-normal)

        .panel-block
            border-radius: 0
            
            table
                th, td
                    padding: 3px

    .modal
        .modal-card-head, .modal-card-foot
            background: #fff
            border: none
            box-shadow: none

        .modal-card-foot
            display: block

    .field.has-addons
        button
            display: inline-block
            height: 100%

    .button, .pagination-prev, .pagination-next
        box-shadow: none

    .navbar-item:hover
        background: none

    .title
        font-weight: 600

    .progress
        --bulma-progress-bar-background-color: darkgray

    // FormKit overrides
    .formkit-messages
        margin: 1em 0

    [data-invalid], [data-errors]
        .formkit-message
            color: red

        .formkit-inner
            .formkit-input
                border-color: red

    .formkit-help,
    .formkit-message
        display: block
        font-size: .75rem
        margin-top: 0.25rem

    .checkbox.formkit-input
        margin-right: 0.5em

    legend
        font-weight: 700
        font-size: 1rem
        margin-bottom: 0.5em

    .formkit-label.radio
        margin-left: 0.5em

    .grow-on-hover:hover
        transition: all 0.5s
        transform: scale(1.5)

    .debug
        position: fixed
        right: 0
        top: 200px
        width: 400px
        height: 1000px
        overflow-y: auto


.fade-leave-active
    transition: opacity 0.5s ease

.fade-enter-from,
.fade-leave-to
    opacity: 0

.slidedown-enter-active,
.slidedown-leave-active
    transform:  translateY(0)
    transition: transform 0.5s ease

.slidedown-enter-from,
.slidedown-leave-to
    transform: translateY(-500%)
</style>
