<template>
    <div class="box p-0 mt-5">
        <div class="drag-drop-file"
            :class="{ dragging: dragging }"
            @drop="handleDrop"
            @dragover="handleDragOver"
            @dragleave="handleDragLeave"
            title="Drag and drop files here">
            <template v-if="files.length">
                <div>
                    <div v-for="file in files"
                        class="columns">
                        <div class="column is-narrow">
                            <figure class="image is-24x24"
                                v-if="showPreview && file.preview">
                                <img :src="file.preview">
                            </figure>
                            <span v-else
                                class="mdi mdi-file-outline mdi-24px"
                                style="line-height: normal;"></span>
                        </div>
                        <div v-if="showFilenames"
                            class="column is-one-third">
                            <router-link v-if="file.route"
                                :to="file.route">{{ file.name }}</router-link>
                            <span v-else>{{ file.name }}</span>
                        </div>
                        <div class="column">
                            {{ fileSizeFormat(file.size) }}
                        </div>
                        <div class="column">
                            <progress v-if="file.state == 'loading'"
                                class="progress is-small is-primary m-1"
                                max="100">{{ file.state }}</progress>
                            <span v-if="file.state == 'complete'"
                                class="has-text-success">
                                <span class="icon">
                                    <span class="mdi mdi-check"></span>
                                </span>
                                Complete
                            </span>
                            <span v-if="file.state == 'error'"
                                class="has-text-danger">
                                <span class="icon">
                                    <span class="mdi mdi-alert-circle mr-1">
                                    </span>
                                </span>
                                {{ file.message }}
                            </span>
                            <span v-if="file.state == 'waiting'">Waiting</span>
                        </div>
                        <div class="column">
                            <a @click="cancel(file)"
                                title="Remove this file from upload list"
                                class="ml-3">
                                <span class="icon">
                                    <span class="mdi mdi-close mr-1"></span>
                                    <span>Remove</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="level my-3">
                    <div class="level-left">
                        <div class="level-item"
                            v-if="files.length > 1">
                            {{ files.length }} file{{ files.length == 1 ? '' : 's' }}, {{ totalFileSizeFormatted }}
                            total
                            size
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div class="buttons is-pulled-right">
                                <a v-if="files.length > 1"
                                    @click="clear"
                                    class="button">
                                    Remove all
                                </a>
                                <a v-if="showUploadButton"
                                    @click="uploadFiles"
                                    class="button is-link">
                                    Upload {{ files.length }} file{{ files.length == 1 ? '' : 's' }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div v-if="!files.length">
                <span v-html="instruction"></span>
            </div>

            <div v-if="message"><em>{{ message }}</em></div>
        </div>
    </div>
    <teleport to="body">
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </teleport>
</template>

<script>
import http from "@/http";
import { fileSizeFormat } from "@/utils";
import { uniq } from "lodash";
import FileWrapper from "../models/FileWrapper";
import ConfirmDialog from "./ConfirmDialog";


const STATES = {
    LOADING: 'loading',
    WAITING: 'waiting',
    COMPLETE: 'complete',
    ERROR: 'error',
}

export default {
    props: {
        modelValue: Object,
        allowedTypes: Array,
        maxFileSize: {
            type: Number,
            default: 120
        },
        maxTotalFileSize: Number,
        maxFiles: {
            type: Number,
            default: 20,
        },
        showPreview: Boolean,
        showFilenames: Boolean,
        data: Object,
        uploadUrl: String,
        enableUpload: {
            type: Boolean,
            default: true,
        },
        uploadMethod: {
            type: String,
            default: 'post',
        },
        grouperFunction: {
            type: Function,
            default: null,
        },
        successRoute: Function,
        confirmOnClick: {
            type: Boolean,
            default: true,
        },
        instruction: {
            type: String,
            default: 'Drag files here',
        },
    },
    components: { ConfirmDialog },
    emits: ['update:modelValue', 'complete'],
    data() {
        return {
            files: [],
            message: null,
            dragging: false,
            processing: false,
        }
    },
    methods: {
        clear() {
            this.files = [];
            this.fileNames = [];
            this.$emit('update:modelValue', this.files);
            this.dragging = false;
            this.message = null;
        },
        cancel(file) {
            this.files = this.files.filter(x => x.name != file.name)
        },
        handleDragOver(e) {
            e.preventDefault();
            e.stopPropagation();
            this.dragging = true;
        },
        handleDragLeave(e) {
            e.preventDefault();
            e.stopPropagation();
            this.dragging = false;
        },
        handleDrop(e) {
            e.stopPropagation();
            e.preventDefault();

            this.dragging = false;
            this.message = null;

            if (this.processing)
                return;

            let files = e.dataTransfer.files;
            let messages = [];
            for (let i = 0; i < files.length; i++) {
                let f = new FileWrapper(files[i]);
                f.state = STATES.WAITING;

                const res = this.getFileStatus(f);
                if (res.allowed)
                    this.files.push(f);
                else
                    messages.push(res.message);
            }

            this.message = uniq(messages).join(', ');

            this.$emit('update:modelValue', this.files);
        },
        getFileStatus(f) {

            // Convert sizes to bytes
            let maxTotalFileSize = (this.maxTotalFileSize || this.maxFileSize * this.maxFiles) * 1024 * 1024;
            let maxFileSize = this.maxFileSize * 1024 * 1024;

            let allowed = true;
            let messages = [];

            if (this.files.length >= this.maxFiles) {
                messages.push("Total number of files exceeded");
                allowed = false;
            }

            if (this.allowedTypes && !this.allowedTypes.includes(f.extension)) {
                messages.push(`File type ${f.extension} is not allowed`);
                allowed = false;
            }

            if (f.size > maxFileSize) {
                messages.push("File size is too large");
                allowed = false;
            }

            if (this.fileNames.includes(f.name)) {
                messages.push("Duplicate file");
                allowed = false;
            }

            if (this.totalFileSize + f.size > maxTotalFileSize) {
                messages.push("Total file size is too large");
                allowed = false;
            }

            return {
                allowed: allowed,
                message: messages.join(", "),
            }
        },
        fileSizeFormat(numBytes) {
            return fileSizeFormat(numBytes);
        },
        async uploadFiles() {
            this.processing = true;

            if (this.confirmOnClick) {
                const ok = await this.$refs.confirmDialog.show({
                    title: 'Upload files',
                    message: 'Are you sure you want to upload these files? ',
                });

                if (!ok)
                    return
            }

            if (this.grouperFunction) {
                const groupedFiles = this.grouperFunction(this.files);
                Object.keys(groupedFiles).forEach(async x => {
                    let files = Object.values(groupedFiles[x]);
                    if (files.length)
                        await this.doUpload(files);
                });

            } else {
                await this.doUpload(this.files);
            }

            this.processing = false;
            this.$emit('complete', null);
        },
        async doUpload(files) {

            console.log(files);

            var formData = new FormData();
            for (var key in this.data)
                formData.append(key, this.data[key]);

            if (files.length == 1) {
                formData.append('file', files[0].file, files[0].file.name);
                files[0].state = STATES.LOADING;
            } else {
                let i = 0;
                for (let f of files) {
                    formData.append(`files[${i}]`, f.file, f.file.name);
                    f.state = STATES.LOADING;
                    i++;
                }
            }

            let httpMethod = http[this.uploadMethod];
            let resp = await httpMethod(this.uploadUrl, formData);
            let data = await resp.json();

            console.log(resp);

            if (data?.length && resp.status == 200) {
                data.forEach((result, i) => {
                    let f = files[i];

                    // f may be undefined because a single uploaded file 
                    // resulted in multiple content objects being returned
                    // e.g. a Pipe Catalog zip file
                    if (f) {
                        f.preview = result.thumbnail;
                        if (this.successRoute)
                            f.route = this.successRoute(result)
                    }
                })

                files.forEach(x => {
                    x.state = STATES.COMPLETE;
                });

            } else if (resp.status == 400) {
                files.forEach(x => {
                    x.message = data;
                    x.state = STATES.ERROR;
                });
            } else {            
                files.forEach(x => {
                    x.message = "Upload failed - " + data;
                    x.state = STATES.ERROR;
                });
            }
        }
    },
    computed: {
        totalFileSize() {
            return this.files.map(x => x.size).reduce((partialSum, a) => partialSum + a, 0);
        },
        totalFileSizeFormatted() {
            return fileSizeFormat(this.totalFileSize);
        },
        fileNames() {
            return this.files.map(x => x.name);
        },
        showUploadButton() {
            return !this.processing
                && this.files.filter(x => x.state == STATES.WAITING).length > 0
                && this.enableUpload;
        },
    },
    watch: {
        modelValue(val) {
            if (val.length == 0 && this.files.length > 0)
                this.clear();
        },
        async allowedTypes() {
            this.files = [];
        }
    },
}
</script>

<style lang="sass">
.drag-drop-file 
    padding: var(--bulma-box-padding)
    background: #eee

    &.dragging
        background: lightyellow
</style>