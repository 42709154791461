<template>
    <div>
        <section-nav :names="sectionRoutes"
            strip="Content"
            class="is-pulled-right">
        </section-nav>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Content Items
        </h2>
        <div class="field is-grouped is-grouped-multiline">
            <content-query-input v-model="filters.q">
            </content-query-input>
            <units-select v-model="filters.units">
            </units-select>
            <application-select v-model="filters.application"
                :show-all="true">
            </application-select>
            <content-type-select v-if="filters.application?.code"
                v-model="filters.type"
                :show-all="true"
                :application="filters.application?.code">
            </content-type-select>
            <content-sub-type-select v-if="filters.type"
                v-model="filters.sub_type"
                :show-all="true"
                :type="filters.type">
            </content-sub-type-select>
            <content-library-select v-model="filters.libraries"
                :show-all="true">
            </content-library-select>
            <clear-button @click="clearFilters"
                v-if="isFiltered">
            </clear-button>
        </div>
        <div class="field is-grouped is-grouped-multiline"
            v-if="show.advanced">
            <div class="control">
                <div class="select">
                    <select v-model="filters.state">
                        <option value="ALL"
                            selected="selected">All states</option>
                        <option value="I">Initial</option>
                        <option value="D">Draft</option>
                        <option value="S">Submitted</option>
                        <option value="R">Under Review</option>
                        <option value="X">Rejected</option>
                        <option value="P">Published</option>
                    </select>
                </div>
            </div>
            <content-creator-select v-model="filters.creator"
                :show-all="true">
            </content-creator-select>
            <content-set-select v-model="filters.sets"
                :application="filters.application?.code"
                :content-types="filters.group?.types"
                :show-all="true"
                group-by="contentTypeName"></content-set-select>
            <discipline-select v-model="filters.discipline"
                :show-all="true">
            </discipline-select>
            <div class="control">
                <div class="select">
                    <select v-model="filters.min_score">
                        <option :value="0">
                            Any search score
                        </option>
                        <option v-for="option in [1, 5, 10, 20, 50, 100]"
                            :value="option">
                            Score > {{ option }}
                        </option>
                    </select>
                </div>
            </div>
            <limit-select v-model="filters.limit"></limit-select>
        </div>
        <div class="field">
            <content-fields-select v-if="showFields"
                v-model="filters.fields"
                :library="filters.libraries">
            </content-fields-select>
        </div>
        <div v-if="selectedTypology || (filters.tags.length && !show.tags) || (filters.fields && !showFields)"
            class="field is-grouped">

            <div v-if="selectedTypology"
                class="control">
                <div class="tags">
                    <a @click="selectedTypology = null"
                        class="tag is-success">
                        {{ selectedTypology }}
                    </a>
                </div>
            </div>
            <div v-if="filters.tags.length && !show.tags"
                class="control">
                <div class="tags">
                    <a v-for="tag in filters.tags"
                        @click="selectTag(tag)"
                        class="tag is-info">
                        {{ tag.name }}
                    </a>
                </div>
            </div>
        </div>
        <tags-list content-type="content.Content"
            :library="filters.libraries?.id"
            :application="filters.application?.code"
            :units="filters.units"
            v-model="filters.tags"
            v-show="show.tags">
        </tags-list>
        <div class="field is-grouped mt-2">
            <div class="control m-2">
                <label class="checkbox">
                    <input type="checkbox"
                        v-model="show.advanced">
                    Show advanced options
                </label>
            </div>
            <div class="control m-2">
                <label class="checkbox">
                    <input type="checkbox"
                        v-model="show.tags">
                    Show tags
                </label>
            </div>
        </div>
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>Image</th>
                    <th>Types</th>
                    <th>Title</th>
                    <th v-if="!filters.application">Application</th>
                    <th>Library</th>
                    <th>Discipline</th>
                    <th>Classification</th>
                    <th>Created by</th>
                    <th>Version</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="content in items"
                    :key="content.id">
                    <td>
                        <a @click="showDetail(content)">
                            <img class="image is-100x100"
                                :src="content.thumbnail" />
                        </a>
                    </td>
                    <td>
                        <ul v-if="content.showAllTypes">
                            <li v-for="symbol in content.symbols"
                                :key="symbol.id">
                                <small v-html="symbol.name"></small>
                            </li>
                        </ul>
                        <ul v-else>
                            <li v-for="symbol in content.symbols.slice(0, 4)"
                                :key="symbol.id">
                                <small v-html="symbol.name"></small>
                            </li>
                        </ul>
                        <a @click="content.showAllTypes = !content.showAllTypes">
                            <small v-if="content.symbols.length > 4 && !content.showAllTypes">
                                Show all {{ content.symbols.length }} symbols
                            </small>
                            <span v-if="content.showAllTypes"
                                class="icon is-pulled-right">
                                <span class="mdi mdi-close"></span>
                            </span>
                        </a>
                    </td>
                    <td>
                        <router-link class="has-text-weight-medium is-size-6"
                            v-html="content.title"
                            :to="{ name: 'content-detail', params: { id: content.id } }">
                        </router-link>
                        <div v-if="content.filename != content.title">{{ content.filename }}</div>
                        <div v-if="content.tags.length"
                            class="tags">
                            <a v-for="tag in content.tags"
                                @click="selectTag(tag)"
                                :key="tag.name"
                                :class="{ 'is-light': !tagIsSelected(tag) }"
                                class="tag is-info">
                                {{ tag.name }}
                            </a>
                        </div>
                        <content-fields v-model="content.fields"
                            v-if="showFields"
                            :selected-fields="filters.fields"
                            @selected="toggleField">
                        </content-fields>
                        <div v-if="content.formats.length">
                            <small>Formats:&nbsp;</small>
                            <small>{{ content.formats.join(", ") }}</small>
                        </div>
                        <div v-if="content.search.score">
                            <small>
                                Search rank:
                                {{ content.search.score.toFixed(1) }}
                            </small>
                        </div>
                        <div v-if="content.search.message">
                            <small class="is-italic">
                                {{ content.search.message }}
                            </small>
                        </div>
                    </td>
                    <td v-if="!filters.application">{{ content.applicationName }}</td>
                    <td>{{ content.libraryCode }}</td>
                    <td>{{ content.disciplineName }}</td>
                    <td>
                        <ul class="tags">
                            <a v-for="typology in content.typologies"
                                @click=selectTypology(typology)
                                :key="typology.id"
                                :class="{ 'is-success': typology.id == selectedTypology?.id }"
                                class="tag">
                                {{ typology }}
                            </a>
                        </ul>
                    </td>
                    <td>
                        <router-link :to="{ name: 'user-detail', params: { id: content.creator.id } }"
                            v-if="content.creator"
                            target="_blank">
                            {{ content.creator.name }}
                        </router-link>
                    </td>
                    <td>{{ content.version }}</td>
                    <td>
                        <router-link :to="{ name: 'content-edit', params: { id: content.id } }">
                            <span class="icon">
                                <span class="mdi mdi-pencil"></span>
                            </span>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
        <detail-dialog ref="detailDialog">
            <div class="columns">
                <div class="column">
                    <image-carousel :images="selectedContent.images"
                        :show-title="false"></image-carousel>
                </div>
                <div class="column">
                    <content-properties :content="selectedContent"
                        :show-title="false"></content-properties>
                </div>
            </div>
        </detail-dialog>
    </div>
</template>

<script>
import ContentFields from "@/content/components/ContentFields";
import ContentFieldsSelect from "@/content/components/ContentFieldsSelect";
import ContentTypes from "@/content/components/ContentTypes";
import ApplicationSelect from "@/core/components/ApplicationSelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import DetailDialog from "@/core/components/DetailDialog";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import ImageCarousel from "@/core/components/ImageCarousel";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import TagsList from "@/core/components/TagsList";
import UnitsSelect from "@/core/components/UnitsSelect";
import http from "@/http";
import { cleanData } from "@/utils.js";
import Content from "../models/Content";
import ContentCreatorSelect from "./ContentCreatorSelect";
import ContentGroupSelect from "./ContentGroupSelect";
import ContentLibrarySelect from "./ContentLibrarySelect";
import ContentProperties from "./ContentProperties";
import ContentQueryInput from "./ContentQueryInput";
import ContentSetSelect from "./ContentSetSelect";
import ContentSubTypeSelect from "./ContentSubTypeSelect";
import ContentTypeSelect from "./ContentTypeSelect";
import TypologyAutocomplete from "./TypologyAutocomplete";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ClearButton,
        ContentCreatorSelect,
        ContentFields,
        ContentFieldsSelect,
        ContentGroupSelect,
        ContentLibrarySelect,
        ContentProperties,
        ContentQueryInput,
        ContentSetSelect,
        ContentSubTypeSelect,
        ContentTypeSelect,
        ContentTypes,
        DetailDialog,
        DisciplineSelect,
        ImageCarousel,
        LimitSelect,
        Pagination,
        SearchInput,
        SectionNav,
        TagsList,
        TypologyAutocomplete,
        UnitsSelect,
    },
    data() {
        return {
            filters: {
                application: null,
                creator: null,
                discipline: null,
                fields: {},
                group: null,
                libraries: null,
                limit: 20,
                min_score: 0,
                offset: 0,
                order_by: null,
                sets: null,
                state: "P",
                tags: [],
                typology_left: null,
                typology_right: null,
                typology_tree: null,
                units: null,
                type: null,
                sub_type: null,
                q: {
                    is_query_string: false,
                    text: null,
                },
            },
            selectedContent: null,
            selectedTypology: null,
            show: {
                advanced: false,
                tags: false,
                queryString: false,
            },
            sectionRoutes: [
                'content-library-list',
                'content-requests',
                'content-images',
                'audit-summary-list',
                'content-sets',
                'content-bulk-update',
                'content-upload',
            ],
            user: null,
        };
    },
    async mounted() {
        document.onkeydown = (evt) => {
            if (evt.key == "Escape")
                this.$refs.detailDialog.cancel();
        };
        this.show.advanced = this._store.get('show')?.advanced || false;
        this.show.tags = this._store.get('show')?.tags || false;
    },
    methods: {
        async loadItems() {
            await this.$store.dispatch("users/loadCurrentUser");
            this.user = this.$store.state.users.currentUser;

            const url = this.isDefiniteSearch
                ? `${process.env.VUE_APP_API_URL_V2}/content/`
                : `${process.env.VUE_APP_API_URL_V2}/content/recommended/`;

            let resp = await http.get(
                url,
                this.cleanedFilters,
                this._abortController.signal);

            if (resp && resp.status == 200) {
                let data = await resp.json();

                this.items = data.results.map((x) => new Content(x));
                this.resultsCount = data.count;

                if (typeof this.resultsCount != 'number')
                    this.resultsCount = this.resultsCount.value;

                const selectedTagNames = this.filters.tags.map(x => x.name);
                if (selectedTagNames.length)
                    this.items.forEach(x => {
                        x.tags.forEach(y => {
                            y.selected = selectedTagNames.includes(y.name);
                        })
                    })
            }
        },
        async showDetail(content) {
            this.selectedContent = content;
            await this.$refs.detailDialog.show({ title: content.title });
        },
        tagIsSelected(tag) {
            return this.filters.tags.map(x => x.name).includes(tag.name);
        },
        selectTag(tag) {
            if (this.tagIsSelected(tag)) {
                this.filters.tags = this.filters.tags.filter(x => x.name != tag.name);
            } else {
                this.filters.tags.push(tag);
            }
        },
        selectTypology(typology) {
            if (this.selectedTypology?.id == typology.id)
                this.selectedTypology = null;
            else
                this.selectedTypology = typology;

            if (this.selectedTypology) {
                this.filters.typology_left = typology.lft;
                this.filters.typology_right = typology.rght;
                this.filters.typology_tree = typology.treeId;
            } else {
                this.filters.typology_left = null;
                this.filters.typology_right = null;
                this.filters.typology_tree = null;
            }
        },
        async loadUrlArgs() {
            let args = this.$route.query;
            if (!args)
                return;

            if (args.library) {
                await this.$store.dispatch("content/loadLibraryList");
                this.filters.libraries = this.$store.getters["content/getLibraryById"](args.library);
            }
        },
        clearFilters() {
            this.filters = Object.assign({}, this._cache.filters);
            this.selectedTypology = null;
        },
        toggleField(field) {
            let val = field.selected ? field.value : null;
            this.filters.fields[field.name] = val;
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.libraries)
                filters.libraries = filters.libraries.code;

            if (filters.application)
                filters.application = filters.application.code;

            if (filters.group)
                filters.group = filters.group.name;

            if (filters.discipline)
                filters.discipline = filters.discipline.name;

            if (this.filters.type)
                filters.type = filters.type

            if (this.filters.sub_type)
                filters.sub_type = filters.sub_type

            if (filters.tags.length)
                filters.tags = filters.tags.map(x => x.name).join(',');
            else
                delete filters.tags;

            if (filters.sets)
                filters.sets = [filters.sets.id];
            else
                delete filters.sets;

            if (filters.creator)
                filters.creator = filters.creator.id

            if (filters.q.is_query_string) {
                filters.query_string = filters.q.text;
                delete filters.q;
            }
            else {
                filters.q = filters.q.text;
            }

            if (this.user)
                filters.user = this.user.id

            if (this.filters.type)
                filters.type = filters.type.code

            if (this.filters.sub_type)
                filters.sub_type = filters.sub_type

            if (filters.fields) {
                filters.fields = Object.entries(this.filters.fields)
                    .map(x => `${x[0]}:${x[1]}`)
                    .join(',');
            }
            else
                delete filters.fields

            return filters;
        },
        isDefiniteSearch() {
            return !!(this.filters.q.text || this.filters.tags.length);
        },
        showFields() {
            return this.show.advanced && this.filters.libraries?.id;
        },
    },
    watch: {
        show: {
            handler(val) {
                this._store.set('show', val)
            },
            deep: true,
        },
        'filters.tags': function (val) {
            if (val.length)
                this.selectedTag = val[0];
        }
    }
};
</script>

<style lang="sass">
.content-list
    progress
        height: 0.5rem
    em
        background-color: #ffff92
        font-weight: 400

    .modal-card
        width: 1000px

        .panel
            border: none

    .tags-list
        .card-content
            max-height: 300px
            overflow-y: auto

    .tags
        margin-bottom: 0.5em

</style>