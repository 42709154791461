<template>
    <div v-if="collection">
        <save-button @click="save"
            :saving="saving"
            class="is-pulled-right"></save-button>
        <h2 class="title">
            <template v-if="collection.id">
                {{ collection.name }}
            </template>
            <template v-else>
                New Shared Parameter Collection
            </template>
        </h2>
        <div class="columns">
            <div class="column is-half">
                <div class="block">
                    <div class="field">
                        <label>Name</label>
                        <div class="control">
                            <input class="input"
                                type="text"
                                v-model="collection.name" />
                        </div>
                    </div>
                    <div class="field">
                        <label>Discipline</label>
                        <discipline-select v-model="collection.discipline"
                            :show-none="true">
                        </discipline-select>
                    </div>
                    <div class="field">
                        <label>Library</label>
                        <content-library-select v-model="collection.library"
                            :show-none="true">
                        </content-library-select>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="checkbox">
                                <input type="checkbox"
                                    v-model="collection.active">
                                Active
                            </label>
                        </div>
                        <div class="help">
                            Active collections will appear in search results
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-narrow">
                <parameter-collection-edit-parameter-search v-model="selectedParameters"
                    @selected="addParameter">
                </parameter-collection-edit-parameter-search>
            </div>
            <div class="column">
                <div class="panel">
                    <div class="panel-heading">
                        Add Category to all Parameters
                    </div>
                    <div class="panel-block is-block">
                        <div class="level">
                            <div class="select">
                                <select v-model="selectedCategory">
                                    <option v-for="category in categories">
                                        {{ category.name }}
                                    </option>
                                </select>
                            </div>
                            <div v-if="selectedCategory"
                                class="buttons is-pulled-right">
                                <button class="button"
                                    @click="addParameterCategory(selectedCategory)">
                                    <span class="icon">
                                        <span class="mdi mdi-plus-thick"></span>
                                    </span>
                                    <span>Add</span>
                                </button>
                                <button class="button"
                                    @click="removeParameterCategory(selectedCategory)">
                                    <span class="icon">
                                        <span class="mdi mdi-minus-thick"></span>
                                    </span>
                                    <span>Remove</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel">
                    <div class="panel-heading">
                        {{ collection.parameters?.length }} Assigned Parameters
                    </div>
                    <div class="panel-block is-block"
                        v-for="parameter in collection.parameters">
                        <div class="columns">
                            <div class="column">
                                <parameter-collection-edit-parameter :parameter="parameter">
                                </parameter-collection-edit-parameter>
                            </div>
                            <div class="column is-narrow">
                                <a @click="removeParameter(parameter)"
                                    title="Remove parameter"
                                    class="is-pulled-right">
                                    <span class="icon">
                                        <span class="mdi mdi-close-thick"></span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentLibrarySelect from '@/content/components/ContentLibrarySelect';
import DisciplineSelect from '@/core/components/DisciplineSelect';
import SaveButton from "@/core/components/SaveButton";
import http from "@/http";
import Parameter from '../models/Parameter';
import ParameterCollection from '../models/ParameterCollection';
import ParameterCollectionEditParameter from './ParameterCollectionEditParameter';
import ParameterCollectionEditParameterSearch from './ParameterCollectionEditParameterSearch';

export default {
    components: {
        ContentLibrarySelect,
        DisciplineSelect,
        ParameterCollectionEditParameter,
        ParameterCollectionEditParameterSearch,
        SaveButton,
    },
    data() {
        return {
            collection: null,
            saving: false,
            selectedParameters: [],
            categories: [],
            selectedCategory: null,
        }
    },
    async created() {
        await this.$store.dispatch("bim/loadParameterCategories");
        this.categories = this.$store.getters["bim/getParameterCategories"];

        if (this.$route.params.id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/${this.$route.params.id}/`)
                .then(resp => resp.json());
            this.collection = new ParameterCollection(data);
        } else {
            this.collection = new ParameterCollection();
        }
    },
    methods: {
        async save() {
            this.saving = true;

            let data = {
                name: this.collection.name,
                active: this.collection.active,
                discipline: this.collection.discipline?.id,
                library: this.collection.library?.code,
                parameters: this.collection.parameters.map(x => {
                    return {
                        id: x.id,
                        categories: x.categories,
                        instance: x.instance,
                        action: x.action,
                        existing_parameter: x.existingParameter,
                    }
                }),
            }

            let result = null;

            if (this.collection.id) {
                result = await http.patch(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/${this.collection.id}/`, data)
                    .then(resp => resp.json());
            } else {
                result = await http.post(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/`, data)
                    .then(resp => resp.json());
            }

            this.saving = false;
            this.$router.push({
                name: 'parameter-collection-detail',
                params: {
                    id: result.id,
                }
            });
        },
        addParameter() {
            for (const parameter of this.selectedParameters) {
                let paramObj = new Parameter(parameter);
                this.removeParameter(paramObj);
                this.collection.parameters.unshift(paramObj);
            }
        },
        removeParameter(parameter) {
            if (this.collection.parameters)
                this.collection.parameters = this.collection.parameters.filter(x => x.name != parameter.name);
        },
        addParameterCategory(category) {
            for (const parameter of this.collection.parameters)
                if (!parameter.categories.includes(category))
                    parameter.categories.push(category);
        },
        removeParameterCategory(category) {
            for (const parameter of this.collection.parameters)
                parameter.categories = parameter.categories.filter(x => x != category);
        },
    },
}
</script>