import { splitFilepath } from "@/utils";

const defaultGrouper = (files) => {
    let filesMap = {};
    files.forEach(x => {
        let fileInfo = splitFilepath(x.name);
        if (filesMap[fileInfo.basename] == undefined)
            filesMap[fileInfo.basename] = {};
        filesMap[fileInfo.basename][fileInfo.ext] = x;
    });
    return filesMap;
}

class ContentType {
    constructor(application, code, title, extensions, coreExtensions, grouperFunction, removeFunction) {
        this.application = application;
        this.code = code;
        this.title = title;
        this.extensions = extensions;
        this.coreExtensions = coreExtensions || [];
        this.groupFiles = grouperFunction || defaultGrouper;
        this.filesToRemove = removeFunction || function (files) { return []; };
    }
}

export default ContentType;