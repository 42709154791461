<template>
    <div v-if="!loading && item">
        <section-nav :routes="sectionRoutes"
            class="is-pulled-right"></section-nav>
        <h2 class="title">{{ item.number }} - {{ item.name }}</h2>
        <div class="box">
            <base-chart :data="chartData"
                :options="chartOptions"></base-chart>
        </div>
        <div class="columns">
            <div class="column"
                v-if="item.models">
                <project-model-list :project="item"></project-model-list>
            </div>
            <div class="column">
                <div class="panel">
                    <div class="panel-heading">
                        <router-link class="is-pulled-right"
                            title="Edit project details"
                            :to="{ name: 'project-edit', params: { id: item.id } }">
                            <span class="mdi mdi-pencil"></span>
                        </router-link>
                        Project Details
                    </div>
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Number:</th>
                                <td>{{ item.number }}</td>
                            </tr>
                            <tr>
                                <th>Name:</th>
                                <td>{{ item.name }}</td>
                            </tr>
                            <tr>
                                <th>Client:</th>
                                <td>
                                    {{ item.client?.name }}
                                </td>
                            </tr>
                            <tr>
                                <th>Address:</th>
                                <td>{{ item.address }}</td>
                            </tr>
                            <tr>
                                <th>Restriction Level:</th>
                                <td>{{ item.restrictionLevelName }}</td>
                            </tr>
                            <tr>
                                <th>Cloud id:</th>
                                <td>
                                    <code v-if="item.bim360Id">{{ item.bim360Id }}</code>
                                </td>
                            </tr>
                            <tr>
                                <th>Initialized:</th>
                                <td>{{ item.initialized }}</td>
                            </tr>
                            <tr v-if="item.folderPaths">
                                <th>Folder paths:</th>
                                <td>
                                    <span>{{ item.folderPaths.join(", ") }}</span>
                                </td>
                            </tr>
                            <tr v-if="item.automationEndpoints">
                                <th>Automation endpoints:</th>
                                <td>
                                    <span>{{ item.automationEndpoints.join(", ") }}</span>
                                </td>
                            </tr>
                            <tr v-if="item.admins">
                                <th>Admins:</th>
                                <td>
                                    {{ item.admins.map((x) => x.name).join(", ") }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="item.restrictionLevel == 'A'"
                    class="panel">
                    <div class="panel-heading">Audit Profiles</div>
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Application</th>
                                <th>Update Frequency (days)</th>
                                <th>Maximum Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="profile in item.auditProfiles">
                                <td>{{ profile.applicationName }}</td>
                                <td>{{ profile.modelUpdateFrequency }}</td>
                                <td>{{ profile.maxWeightName }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <project-user-list :project="item"
                    v-if="item.users"> </project-user-list>
                <div v-if="item.filenameFormats.length"
                    class="panel">
                    <div class="panel-heading">
                        Content Filename Formats
                        <router-link :to="{ name: 'page-detail-slug', params: { slug: 'content-filenames' } }"
                            target="_blank"
                            title="What is this?"
                            class="is-pulled-right">
                            <span class="icon">
                                <span class="mdi mdi-help-circle"></span>
                            </span>
                        </router-link>
                    </div>
                    <table class="table">
                        <tr v-for="format in item.filenameFormats">
                            <th>{{ format.application.name }}:</th>
                            <td><code>{{ format.format }}</code></td>
                        </tr>
                    </table>
                </div>
                <div v-if="item.sets.length"
                    class="panel">
                    <div class="panel-heading">Associated Content Sets</div>
                    <div v-for="set in item.sets"
                        class="panel-block">
                        {{ set.name }}
                    </div>
                </div>
                <div v-if="item.contentLibraries.length"
                    class="panel">
                    <div class="panel-heading">Associated Content Libraries</div>
                    <div v-for="library in item.contentLibraries"
                        class="panel-block">
                        {{ library }}
                    </div>
                </div>
                <notifications template-name="panel"
                    :project="item">
                </notifications>
                <router-link v-if="userCanCreateNotification"
                    :to="{ name: 'notification-add', query: { project: item.id } }"
                    class="button">
                    <span class="icon">
                        <span class="mdi mdi-message-outline"></span>
                    </span>
                    <span>
                        Create project notification
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { COLORS } from "@/constants";
import BaseChart from "@/core/components/BaseChart";
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import Notifications from '@/core/components/Notifications';
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import moment from "moment";
import ModelAudit from "../models/ModelAudit";
import Project from "../models/Project";
import ProjectModelList from "./ProjectModelList.vue";
import ProjectUserList from "./ProjectUserList.vue";
import { PERMISSIONS } from "@/constants";

export default {
    mixins: [BaseDetailComponent],
    components: {
        BaseChart,
        Notifications,
        ProjectModelList,
        ProjectUserList,
        SectionNav,
    },
    data() {
        return {
            lastAudit: null,
            chartData: null,
            chartOptions: null,
            audits: [],
            sectionRoutes: [],
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/projects/${this.$route.params.id}/`,
            modelClass: Project,
        };
    },
    computed: {
        userCanCreateNotification() {
            return this.user?.hasPermission(PERMISSIONS.ADD_NOTIFICATION);
        }
    },
    methods: {
        async setup() {
            this.sectionRoutes = [
                { name: "stats-events", query: { project: this.item.id } },
                { name: "content-usage", query: { project: this.item.id } },
                { name: "audit-element-list", query: { project: this.item.id } },
            ];

            // Load last audit
            let auditData = await http
                .get(
                    `
                ${process.env.VUE_APP_API_URL_V1}/audits/`,
                    { project: this.item.id }
                )
                .then((resp) => resp.json());
            let audits = auditData.results.map((x) => new ModelAudit(x));
            this.lastAudit = audits[0];

            // Load chart data
            const start = moment().subtract(100, "days").format("YYYY-MM-DD");
            let chartData = await http
                .get(`${process.env.VUE_APP_API_URL_V1}/stats/model/grouped/`, {
                    project: this.item.id,
                    by: "day",
                    start: start,
                    fill: "1",
                })
                .then((resp) => resp.json());

            // Get audits for each day
            let matchingAudits = [];
            chartData.forEach((x) => {
                let periodDate = moment(x.period, "YYYY-MM-DD");
                let matchingAudit = this.audits.find((y) =>
                    y.created.isSame(periodDate, "day")
                );
                matchingAudits.push(matchingAudit ? matchingAudit.score : null);
            });

            // Build chart data
            this.chartData = {
                labels: chartData.map((x) => moment(x.period).format("YYYY-MM-DD")),
                datasets: [
                    {
                        label: "Recent model activity",
                        data: chartData.map((x) => x.count),
                        backgroundColor: COLORS.GREEN,
                        borderWidth: 0,
                        yAxisID: "A",
                        type: "bar",
                    },
                    {
                        label: "Model audits",
                        data: matchingAudits,
                        backgroundColor: COLORS.BLUE,
                        borderColor: COLORS.BLUE,
                        yAxisID: "B",
                        type: "line",
                        spanGaps: true,
                    },
                ],
            };

            // Build chart options
            this.chartOptions = {
                scales: {
                    B: {
                        position: "right",
                        min: 0,
                        max: 100,
                    },
                },
            };
        },
    },
};
</script>
