import BaseData from "@/core/models/BaseData";
import { TYPES } from "@/stats/constants.js";

class StatsEvent extends BaseData {
    constructor(data) {
        super(data);
        this.typeDescription = TYPES[this.event][this.type] || this.type;
        this.data = this.data || {};
    }
}

export default StatsEvent;