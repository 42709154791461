import store from '@/store';
import { urlEncode } from '@/utils';
import Cookies from 'js-cookie';

const dispatch = async (method, url, data, signal) => {
    let options = {
        method: method,
        credentials: 'include',
        headers: {},
    };

    if (method == 'GET') {
        if (data)
            url += '?' + urlEncode(data);
    } else {
        options.mode = 'cors';
        options.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');

        if (data instanceof FormData) {
            options.body = data;
        } else {
            options.body = JSON.stringify(data);
            options.headers['Content-Type'] = 'application/json';
        }
    }

    if (signal != undefined)
        options.signal = signal;

    store.commit("core/SET_LOADING", true);

    let resp = null;

    try {
        resp = await fetch(url, options);
    } catch (err) {
        console.log(err);
    } finally {
        store.commit("core/SET_LOADING", false);
    }

    return resp;
}

export default {
    /**
     * Handle GET request
     * @param url
     * @param signal
     * @returns Fetch promise
     */
    async get(url, data, signal) {
        // TODO Cache get requests - use memoize function?
        return await dispatch('GET', url, data, signal);
    },
    /**
     * Handle POST request
     * @param url
     * @param signal
     * @returns Fetch promise
     */
    async post(url, data, signal) {
        return await dispatch('POST', url, data, signal);
    },
    /**
     * Handle PUT request
     * @param url
     * @param signal
     * @returns Fetch promise
     */
    async put(url, data, signal) {
        return await dispatch('PUT', url, data, signal);
    },
    /**
     * Handle PATCH request
     * @param url
     * @param signal
     * @returns Fetch promise
     */
    async patch(url, data, signal) {
        return await dispatch('PATCH', url, data, signal);
    },
    /**
    * Handle DELETE request
    * @param url
    * @param signal
    * @returns Fetch promise
    */
    async delete(url, data, signal) {
        return await dispatch('DELETE', url, null, signal);
    }
}
