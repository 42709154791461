const TYPES = {
    Model: {
        AI: 'Application Initialized',
        DO: 'Document Opened',
        DS: 'Document Saved',
        DC: 'Document Closed',
        DM: 'Document Modified',
        YS: 'Sync Started',
        YE: 'Sync Ended',
        YC: 'Sync Cancelled',
        FE: 'Family Loaded',
    },
    Tool: {
        O: 'Tool Opened',
        E: 'Tool Error',
        C: 'Tool Closed',
        U: 'Tool Used',
    },
    Project: {
        PC: 'Project Created',
        PU: 'Project Updated',
        MC: 'Model Created',
        MU: 'Model Updated',
    },
    Content: {
        D: 'Content Downloaded',
        V: 'Content Viewed',
        L: 'Content Loaded',
    }
}

const noFormat = (field, val) => val;

const changeFormat = (field, val) => {
    if (!val) {
        return '';
    } else {
        return Object.values(val).map(x => `<code>${x}</code>`).join(' → ');
    }
}

const docModifiedFormat = (field, val) => {
    if (!val) {
        return '';
    } else if (typeof val == 'string') {
        return val;
    } else if (field == 'action' || field == 'type') {
        return val;
    } else if (field == 'elements') {
        return val.map(y => y.name).join('<br>');
    } else {
        return val;
    }
}

const DATA_FIELD_FORMATTERS = {
    Model: {
        AI: noFormat,
        DO: noFormat,
        DS: noFormat,
        DC: noFormat,
        DM: docModifiedFormat,
        YS: noFormat,
        YE: noFormat,
        YC: noFormat,
        FE: noFormat,
    },
    Tool: {
        O: noFormat,
        E: noFormat,
        C: noFormat,
        U: noFormat,
    },
    Project: {
        PC: noFormat,
        PU: changeFormat,
        MC: noFormat,
        MU: changeFormat,
    },
    Content: {
        D: noFormat,
        V: noFormat,
    }
}

export { TYPES, DATA_FIELD_FORMATTERS };
