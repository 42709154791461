<template>
    <div>
        <section-nav :names="['project-add', 'user-manage']"
            class="is-pulled-right"></section-nav>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Projects
        </h2>
        <div class="field is-grouped">
            <search-input v-model="filters.q"
                placeholder="Search by name or number" />
            <limit-select v-model="filters.limit" />
            <clear-button @click="clearFilters"
                v-if="isFiltered">
            </clear-button>
        </div>
        <table class="table is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Application Version</th>
                    <th>Restriction Level</th>
                    <th>Models</th>
                    <th>Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="project in items"
                    v-bind:key="project.number">
                    <td>
                        <router-link :to="{
                            name: 'project-detail',
                            params: { id: project.id }
                        }">{{ project.number }}</router-link>
                    </td>
                    <td>{{ project.name }}</td>
                    <td>{{ project.revitVersion }}</td>
                    <td>{{ project.restrictionLevelName }}</td>
                    <td>{{ project.modelCount }}</td>
                    <td>{{ project.created.fromNow() }}</td>
                    <td>
                        <router-link :to="{
                            name: 'project-edit',
                            params: { id: project.id },
                        }">
                            <span class="mdi mdi-pencil"></span>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import http from '@/http';
import Project from "../models/Project";

export default {
    mixins: [BaseListComponent],
    components: {
        ClearButton,
        LimitSelect,
        Pagination,
        SearchInput,
        SectionNav,
    },
    data() {
        return {
            filters: {
                q: null,
                limit: null,
                offset: 0,
            },
        };
    },
    methods: {
        async loadItems() {
            let resp = await http.get(
                `${process.env.VUE_APP_API_URL_V1}/projects/`,
                this.cleanedFilters,
                this._abortController.signal);

            if (resp && resp.status == 200) {
                let data = await resp.json();
                this.resultsCount = data.count;
                this.items = data.results.map(x => new Project(x));
            }
        },
    },
};
</script>
