<template>
    <div>
        <div class="mb-2">{{ parameter.name }} - {{ parameter.dataType }}</div>
        <div class="field is-grouped">
            <div class="control">
                <a @click="parameter.instance = false"
                    class="button is-small"
                    v-if="parameter.instance">Instance</a>
                <a @click="parameter.instance = true"
                    class="button is-small"
                    v-else>Type</a>
            </div>
            <div class="control">
                <div class="field has-addons">
                    <div class="control">
                        <div class="select is-small">
                            <select v-model="parameter.action">
                                <option v-for="action in actions"
                                    :value="action[0]">{{ action[1] }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="control">
                        <input v-if="['S', 'LN', 'LE'].includes(parameter.action)"
                            v-model="parameter.existingParameter"
                            type="text"
                            placeholder="Existing parameter name"
                            required
                            class="input is-small">
                    </div>
                </div>
            </div>
            <div class="control">
                <ul>
                    <li v-for="category in parameter.categories"
                        class="is-size-7 p-0 m-0">
                        <span>{{ category }}</span>
                        <a @click="removeParameterCategory(parameter, category)"
                            class="icon">
                            <span class="mdi mdi-close"></span>
                        </a>
                    </li>
                </ul>
                <a v-if="!parameter.showNewCategory"
                    @click="parameter.showNewCategory = true"
                    class="button is-small">
                    <span class="icon">
                        <span class="mdi mdi-plus"></span>
                    </span>
                    <span>Add category</span>
                </a>
                <div v-else
                    class="field has-addons">
                    <div class="control">
                        <div class="select is-small">
                            <select v-model="parameter.newCategory">
                                <option v-for="category in categories">
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="control"
                        v-if="parameter.newCategory">
                        <a @click="addParameterCategory(parameter, parameter.newCategory)"
                            class="button is-small"
                            title="Add this category">
                            <span class="icon">
                                <span class="mdi mdi-check"></span>
                            </span>
                        </a>
                    </div>
                    <div class="control">
                        <a @click="parameter.showNewCategory = false"
                            class="button is-small">
                            <span class="icon">
                                <span class="mdi mdi-close"></span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PARAMETER_ACTIONS } from "../constants";

export default {
    props: ['parameter'],
    data() {
        return {
            categories: [],
            actions: Object.entries(PARAMETER_ACTIONS),
        }
    },
    async created() {
        await this.$store.dispatch("bim/loadParameterCategories");
        this.categories = this.$store.getters["bim/getParameterCategories"];
    },
    methods: {
        addParameterCategory(parameter, category) {
            this.removeParameterCategory(parameter, category);
            parameter.categories.push(category);
        },
        removeParameterCategory(parameter, category) {
            parameter.categories = parameter.categories.filter(x => x != category);
        },
    }
}
</script>