<template>
    <div class="control">
        <div class="field is-grouped">
            <div v-for="field in fields">
                <div class="field has-addons">
                    <div class="control">
                        <button class="button is-static">
                            {{ field.name }}
                        </button>
                    </div>
                    <div class="control">
                        <div v-if="field.options"
                            class="select">
                            <select v-model="field.value"
                                class="form-control">
                                <option :value="null">All values</option>
                                <option v-for="option in field.options"
                                    :value="option">{{ option }}</option>
                            </select>
                        </div>
                        <input v-else
                            v-model="field.value"
                            type="text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/http';
import ContentLibrary from '../models/ContentLibrary';
import { isEmpty } from 'lodash';

export default {
    props: {
        modelValue: Object,
        library: ContentLibrary
    },
    emits: ['update:modelValue'],
    data() {
        return {
            fields: [],
        }
    },
    async created() {
        await this.loadFields();
    },
    computed: {
        selectedFields() {
            let fieldValues = {};
            this.fields.filter(x => x.value).forEach(x => fieldValues[x.name] = x.value);
            return fieldValues;
        }
    },
    methods: {
        async loadFields() {
            this.fields = await http.get(
                `${process.env.VUE_APP_API_URL_V2}/content/fields/`,
                { libraries: this.library?.id })
                .then(resp => resp.json());

            this.fields.forEach(x => {
                x.value = null;
                x.options = x.options || x.values;
            });
        },
    },
    watch: {
        async library() {
            await this.loadFields();
        },
        selectedFields(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue: {
            handler: function (val) {
                if (isEmpty(val))
                    return;

                const fieldValue = Object.entries(val)[0];
                this.fields
                    .filter(x => x.name == fieldValue[0])
                    .forEach(x => x.value = fieldValue[1]);
            },
            deep: true,
        }
    }
}
</script>