<template>
    <div v-if="item">
        <router-link :to="{ name: 'content-detail', params: { id: item.id } }"
            class="button is-pulled-right">
            Content object
        </router-link>
        <h2 class="title">
            {{ item }} - {{ item.associatedFiles.length }}
            Associated {{ item.associatedFiles.length != 1 ? 'Files' : 'File' }}
        </h2>
        <div class="columns">
            <div class="column is-one-third">
                <div v-for="group in groupedFiles"
                    class="panel">
                    <div class="panel-heading">
                        <div>{{ group[0] }}</div>
                    </div>
                    <div v-for="file in group[1]"
                        class="panel-block is-block is-clearfix">
                        <a :href="file.downloadUrl">
                            <span class="mdi mdi-file-outline"></span>
                            {{ file.title }}
                        </a>
                        <a @click="deleteFile(file)"
                            class="is-pulled-right"
                            title="Delete associated file">
                            <span class="icon">
                                <span class="mdi mdi-close-thick"></span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="panel">
                    <div class="panel-heading">
                        <div>Upload New File</div>
                    </div>
                    <div class="panel-block">
                        <div class="field is-grouped">
                            <div class="control">
                                <div class="select">
                                    <select v-model="selectedFileType">
                                        <option v-for="filetype in fileTypes"
                                            :key="filetype[0]"
                                            :value="filetype[0]">
                                            {{ filetype[1] }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="control">
                                <input v-model="title"
                                    type="text"
                                    class="input"
                                    placeholder="Title">
                            </div>
                            <div class="control">
                                <input type="file"
                                    @change="previewFile"
                                    ref="fileInput"
                                    class="p-2">
                            </div>
                            <div v-if="uploadFile"
                                class="control">
                                <save-button :saving="saving"
                                    @click="upload">Upload</save-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="isReplaceableFile"
                        class="panel-block">
                        <em>New file will replace the existing {{ getFiletypeName() }} file</em>
                    </div>
                </div>
            </div>
        </div>
        <teleport to="body">
            <confirm-dialog ref="confirmDialog"></confirm-dialog>
        </teleport>
    </div>
</template>

<script>
import BaseDetailComponent from "@/core/components/BaseDetailComponent";
import ConfirmDialog from "@/core/components/ConfirmDialog";
import http from "@/http";
import { showMessage } from "@/utils";
import { groupBy } from "lodash";
import SaveButton from "../../core/components/SaveButton";
import { ASSOCIATED_FILETYPES } from "../constants";
import Content from "../models/Content";
import ContentAssociatedFile from "../models/ContentAssociatedFile";

const MAX_FILESIZE = 10 * 1024 * 1024;

export default {
    mixins: [BaseDetailComponent],
    components: {
        ConfirmDialog,
        SaveButton,
    },
    data() {
        return {
            detailUrl: `${process.env.VUE_APP_API_URL_V2}/content/${this.$route.params.id}/`,
            modelClass: Content,
            fileTypes: Object.entries(ASSOCIATED_FILETYPES),
            uploadFile: null,
            selectedFileType: 'GE',
            title: null,
            saving: false,
        };
    },
    computed: {
        groupedFiles() {
            return Object.entries(groupBy(this.item.associatedFiles, x => x.typeName));
        },
        isReplaceableFile() {
            for (const fileType of ['TC', 'IP', 'CP'])
                if (this.selectedFileType == fileType && this.item.associatedFiles.some(x => x.type == fileType))
                    return true;

            return false;
        },
    },
    methods: {
        getFiletypeName() {
            return ASSOCIATED_FILETYPES[this.selectedFileType]
        },
        async setup() {
            this.emitter.emit("breadcrumbs", [
                { route: 'home', name: 'Home' },
                { route: 'content-list', name: 'All Content' },
                { route: 'content-detail', name: this.item.toString(), params: { id: this.item.id } },
            ]);
        },
        async deleteFile(file) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete Associated File',
                message: `Are you sure you want to delete ${file}? `,
            });

            if (!ok)
                return

            let resp = await http.delete(`${process.env.VUE_APP_API_URL_V2}/content/associated-files/${file.id}/`);
            if (resp.status == 204) {
                this.item.associatedFiles = this.item.associatedFiles.filter(x => x.id != file.id);
                showMessage("Associated file deleted");
            } else {
                showMessage("Associated file could not be deleted", 'danger');
            }
        },
        async upload() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Upload File',
                message: `Are you sure you want upload ${this.uploadFile.name}?`,
            });

            if (!ok)
                return

            this.saving = true;
            this.title = this.title || this.uploadFile.name;

            let fd = new FormData()
            fd.append('attachment', this.uploadFile, this.uploadFile.name);
            fd.append('title', this.title);
            fd.append('type', this.selectedFileType);
            fd.append('content', this.item.id);

            let resp = await http.post(`${process.env.VUE_APP_API_URL_V2}/content/associated-files/`, fd);
            if (resp.status == 201) {
                let data = await resp.json();
                this.item.associatedFiles.push(new ContentAssociatedFile(data));
                showMessage(`${this.uploadFile.name} successfully uploaded`);

                // Reset form
                this.$refs.fileInput.value = '';
                this.uploadFile = null;
                this.title = null;

                this.loadItem();
            } else {
                showMessage(`${this.uploadFile.name} could not be uploaded`, 'danger');
            }

            this.saving = false;
        },
        previewFile(event) {
            let f = event.target.files[0];
            if (f.size > MAX_FILESIZE) {
                event.target.value = '';
                showMessage(`Maximum filesize is ${MAX_FILESIZE / 1024 / 1024}mb`, 'danger');
            } else {
                this.uploadFile = f;
            }
        },
    },
}
</script>