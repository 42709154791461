<template>
    <div>
        <section-nav :names="['content-sets-add']"
            class="is-pulled-right"></section-nav>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Content Sets
        </h2>
        <div class="field is-grouped">
            <search-input v-model="filters.q"
                placeholder="Search content sets" />
            <div class="control m-2">
                <label class="checkbox">
                    Hide user favourites
                    <input v-model="filters.no_favs"
                        class="checkbox"
                        type="checkbox">
                </label>
            </div>
        </div>
        <table class="table is-fullwidth mt-5 is-striped">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Projects</th>
                    <th>User</th>
                    <th>Content items</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="contentSet in items">
                    <td>
                        {{ contentSet.name }}
                    </td>
                    <td>
                        <span v-if="contentSet.projects.length">
                            {{ contentSet.projects.length }}
                        </span>
                    </td>
                    <td>
                        {{ contentSet.userprofile }}
                    </td>
                    <td>
                        {{ contentSet.content.length }}
                    </td>
                    <td>
                        <span v-if="userHasAddPermission"
                            class="icon">
                            <button class="button is-ghost"
                                @click="cloneSet(contentSet)">
                                <span class="icon">
                                    <span class="mdi mdi-content-copy"></span>
                                </span>
                            </button>
                        </span>
                    </td>
                    <td>
                        <span v-if="userHasEditPermission"
                            class="icon">
                            <router-link :to="{ name: 'content-sets-edit', params: { id: contentSet.id } }"
                                class="button is-ghost">
                                <span class="icon">
                                    <span class="mdi mdi-pencil"></span>
                                </span>
                            </router-link>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
        <input-dialog ref="cloneDialog">
        </input-dialog>
    </div>
</template>

<script>
import BaseListComponent from "@/core/components/BaseListComponent";
import InputDialog from "@/core/components/InputDialog";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import { cleanData, showMessage } from "@/utils";
import { PERMISSIONS } from "../../constants";
import ContentSet from "../models/ContentSet";

export default {
    mixins: [BaseListComponent],
    components: {
        Pagination,
        SearchInput,
        InputDialog,
        SectionNav,
    },
    data() {
        return {
            listUrl: `${process.env.VUE_APP_API_URL_V2}/content/sets/`,
            modelClass: ContentSet,
            filters: {
                no_favs: true,
                limit: 50,
                offset: 0,
            }
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (!filters.no_favs)
                delete filters.no_favs;

            return filters;
        },
        userHasEditPermission() {
            return this.user.hasPermission(PERMISSIONS.CHANGE_CONTENTSET);
        },
        userHasAddPermission() {
            return this.user.hasPermission(PERMISSIONS.ADD_CONTENTSET);
        },
    },
    methods: {
        async cloneSet(set) {
            const result = await this.$refs.cloneDialog.show({
                title: `Clone Set - ${set.name}`,
                message: 'Please enter a name for the new cloned set.'
            });

            if (result == false)
                return;

            const data = {
                name: result,
                content: set.content,
            }

            let resp = await http.post(`${process.env.VUE_APP_API_URL_V2}/content/sets/`, data);
            if (resp.status == 201) {
                let returnData = await resp.json();
                this.$router.push({
                    name: 'content-sets-edit',
                    params: { id: returnData.id }
                });
                showMessage(`Content set ${returnData.name} created`);
            } else {
                showMessage('Content set could not be created', 'danger');
            }
        }
    }
}
</script>
