<template>
    <div>
        <h2 class="title">Upload Shared Parameter File</h2>
        <div class="columns">
            <div class="column is-half">
                <FormKit type="form"
                    #default="{ value }"
                    @submit="save">
                    <FormKit type="radio"
                        label="Mode"
                        name="mode"
                        :options="{ N: 'Create new', E: 'Update existing' }"></FormKit>
                    <FormKit v-if="value.mode == 'E'"
                        type="select"
                        name="collection"
                        label="Collection"
                        :options="collections"></FormKit>
                    <FormKit v-if="value.mode == 'N'"
                        type="text"
                        name="name"
                        label="Name"></FormKit>
                    <FormKit type="file"
                        label="File"
                        name="file"
                        validation="required"></FormKit>
                </FormKit>
            </div>
            {{ collection }}
        </div>
    </div>
</template>

<script>
import { FormKit } from "@formkit/vue";
import http from "@/http";

export default {
    components: { FormKit },
    data() {
        return {
            collections: [],
        }
    },
    async created() {
        let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/collections/?limit=9999`)
            .then(resp => resp.json());
        this.collections = data.results.map(x => {
            return {
                value: x.id,
                label: x.name,
            }
        });
    },
    methods: {
        async save(val) {
            console.log(val);

            let formData = new FormData();
            formData.append('mode', val.mode);
            formData.append('file', val.file[0].file);

            if (val.mode == 'E')
                formData.append('collection', val.collection);
            else if (val.mode == 'N')
                formData.append('name', val.name);

            let result = await http.post(
                `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/upload/`,
                formData)
                .then(resp => resp.json());

            if (result?.id)
                this.$router.push({
                    name: 'parameter-collection-detail',
                    params: {
                        id: result.id
                    }
                });
        }
    }
}

</script>