<template>
    <div class="control">
        <div class="select">
            <select placeholder="All disciplines"
                v-model="selectedDiscipline">
                <option :value="null"
                    v-if="showNone">None</option>
                <option :value="null"
                    v-if="showAll">All disciplines</option>
                <option v-for="discipline in disciplines"
                    :value="discipline">{{ discipline.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Object,
        showNone: {
            type: Boolean,
            default: false,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedDiscipline: null,
        }
    },
    created() {
        this.selectedDiscipline = this.modelValue;
        this.$store.dispatch("core/loadDisciplineList");
    },
    computed: {
        disciplines() {
            return this.$store.getters["core/getDisciplines"];
        },
    },
    watch: {
        selectedDiscipline(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedDiscipline = val;
        }
    }
}
</script>