<template>
    <div v-if="parameter">
        <h2 class="title">
            <template v-if="parameter.id">
                Edit Parameter: {{ parameter.name }}
            </template>
            <template v-else>
                Add Parameter: {{ parameter.name }}
            </template>
        </h2>
        <FormKit type="form"
            @submit="save">
            <div class="columns">
                <div class="column">
                    <FormKit type="text"
                        label="Name"
                        help="Enter a parameter name"
                        validation="required"
                        v-model="parameter.name"></FormKit>
                </div>
                <div class="column">
                    <FormKit type="text"
                        label="Descriptor"
                        help="A human readable string to distinguish between parameters with same name"
                        v-model="parameter.descriptor"></FormKit>
                </div>
            </div>
            <FormKit type="textarea"
                label="Description"
                help="Tooltip description"
                validation="length:0,250"
                v-model="parameter.description"></FormKit>
            <div class="columns">
                <div class="column">
                    <FormKit type="text"
                        label="GUID"
                        validation="required"
                        v-model="parameter.guid"
                        disabled>
                    </FormKit>
                </div>
                <div class="column">
                    <FormKit type="select"
                        label="Data type"
                        validation="required"
                        placeholder="Select a data type"
                        :options="types"
                        v-model="parameter.data_type"></FormKit>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <label class="label">Group</label>
                    <div class="control">
                        <group-autocomplete v-model="parameter.group">
                        </group-autocomplete>
                    </div>
                </div>
                <div class="column">
                    <div class="control">
                        <FormKit type="select"
                            label="Binding group"
                            validation="required"
                            placeholder="Select a group"
                            :options="bindingGroups"
                            v-model="parameter.binding_group">
                        </FormKit>
                    </div>
                </div>
            </div>
            <FormKit type="checkbox"
                label="Visible"
                v-model="parameter.visible"></FormKit>
            <FormKit type="checkbox"
                label="User modifiable"
                v-model="parameter.user_modifiable"></FormKit>
            <FormKit type="checkbox"
                label="Instance"
                v-model="parameter.instance"></FormKit>
        </FormKit>
    </div>
</template>

<script>
import http from "@/http";
import { FormKit } from "@formkit/vue";
import GroupAutocomplete from "./GroupAutocomplete.vue";

export default {
    components: {
        FormKit,
        GroupAutocomplete,
    },
    data() {
        return {
            parameter: null,
            types: [],
            groups: [],
            bindingGroups: [],
        };
    },
    async mounted() {
        await this.$store.dispatch("bim/loadParameterTypes");
        this.types = this.$store.getters["bim/getParameterTypes"];

        await this.$store.dispatch("bim/loadParameterBindingGroups");
        this.bindingGroups = this.$store.getters["bim/getParameterBindingGroups"];

        if (this.$route.params.id) {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/${this.$route.params.id}/`)
                .then(resp => resp.json());

                this.parameter = data;
        } else {
            this.parameter = {
                name: '',
                descriptor: '',
                description: '',
                guid: crypto.randomUUID(),
                data_type: '',
                group: '',
                binding_group: '',
                visible: true,
                user_modifiable: true,
            }
        }
    },
    methods: {
        async save() {
            let success = false;
            let resp = null;

            if (this.parameter.id) {
                // Edit form - make http patch request
                resp = await http.patch(
                    `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/${this.parameter.id}/`,
                    this.parameter);
                success = resp.status_code == 200;
            } else {
                // Add form - make http post request
                resp = await http.post(
                    `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/`,
                    this.parameter);
                success = resp.status_code == 201;
            }

            if (success)
                this.$router.push({
                    name: "parameter-list",
                });
        },
    },
}
</script>
