import { APPLICATIONS } from "@/constants";
import { STATES } from "@/content/constants";
import BaseData from "@/core/models/BaseData";
import Tag from "@/core/models/Tag";
import store from "@/store";
import ContentAssociatedFile from "./ContentAssociatedFile";
import ContentTypology from "./ContentTypology";


class Content extends BaseData {
    constructor(data) {
        super(data);
        this.showAllTypes = false;
        this.associatedFiles = data.associated_files?.map(x => new ContentAssociatedFile(x));
        this.typologies = data.typologies?.map(x => new ContentTypology(x));
        this.unitsName = this.units == 'I' ? 'Imperial' : 'Metric';
        this.tags = this.tags?.map(x => new Tag({ name: x }));
        this.versions = this.versions || [];

        if (this.files)
            this.formats = this.files.map(x => x.application_format);

        if (data.search) {
            const highlight = data.search.highlight;
            if (highlight) {
                if (highlight.title)
                    this.title = highlight.title[0];
                if (highlight['symbols.name']) {
                    for (let i = 0; i < highlight['symbols.name'].length; i++)
                        this.symbols[i].name = highlight['symbols.name'][i];
                }
            }
        }
        this._loaded = false;
    }

    toString() {
        return this.title;
    }

    async load() {
        if (this._loaded)
            return;

        if(this.typologies)
            this.typologies.forEach(async x => await x.load());

        await store.dispatch("content/loadLibraryList");
        let libraryObject = store.getters["content/getLibraryByCode"](this.library?.code || this.library);
        if (libraryObject)
            this.library = libraryObject;

        await store.dispatch("core/loadDisciplineList");
        if (typeof this.discipline == 'number')
            this.discipline = store.getters["core/getDisciplineById"](this.discipline);
        else if (typeof this.discipline == 'string')
            this.discipline = store.getters["core/getDisciplineByName"](this.discipline);

        await store.dispatch("core/loadApplicationsList");
        this.application = store.getters["core/getApplicationByCode"](this.application?.code || this.application)

        await store.dispatch("content/loadTypeList");
        this.type = store.getters["content/getTypeByCode"](this.type?.code || this.type)

        await store.dispatch("content/loadVersions", this.id);
        this.versions = store.getters["content/getVersions"](this.id);
        this._loaded = true;
    }

    get stateName() {
        if (this.state)
            return STATES.find(x => x.code == this.state).title;
    }

    get wipStateName() {
        if (this.wipState)
            return STATES.find(x => x.code == this.wipState).title;
    }

    get currentVersion() {
        if (!this._loaded)
            return null;

        const currentVersions = this.versions.filter(x => x.isCurrent);
        if (currentVersions.length > 0)
            return currentVersions[0];
    }

    // Handle fields that may be a string or an object depending on context
    get applicationName() {
        if (this.application?.name)
            return this.application.name;
        else
            return APPLICATIONS[this.application] || this.application
    }

    get libraryCode() {
        if (this.library?.code)
            return this.library.code;
        else
            return this.library;
    }

    get disciplineName() {
        if (this.discipline?.name)
            return this.discipline.name;
        else
            return this.discipline;
    }

    get typeName() {
        if (this.type?.name)
            return this.type.name;
        else
            return this.type;
    }
}

export default Content;
