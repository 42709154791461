<template>
    <div>
        <section-nav :names="['parameter-add', 'parameter-collection-list']"
            class="is-pulled-right"></section-nav>
        <h2 class="title"
            v-if="items">
            {{ pageTitle }} Shared Parameters
        </h2>
        <div class="field is-grouped">
            <search-input v-model="filters.q"
                placeholder="Search name&hellip;">
            </search-input>
            <search-input v-model="filters.guid"
                placeholder="Search GUID&hellip;">
            </search-input>
            <discipline-select v-model="filters.discipline"
                :show-all="true">
            </discipline-select>
            <content-library-select v-model="filters.library"
                :show-all="true">
            </content-library-select>
            <limit-select v-model="filters.limit"></limit-select>
            <clear-button @click="clearFilters"
                v-show="isFiltered">
            </clear-button>
        </div>
        <table class="table is-fullwidth mt-5">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>GUID</th>
                    <th>Data Type</th>
                    <th>Group</th>
                    <th>Invisible</th>
                    <th>Instance</th>
                    <th>Disciplines</th>
                    <th>Libraries</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="parameter in items"
                    :key="parameter.id">
                    <td>
                        <router-link :to="{ name: 'parameter-edit', params: { id: parameter.id } }">
                            {{ parameter.name }}
                        </router-link>
                    </td>
                    <td>
                        {{ parameter.description }}
                    </td>
                    <td>
                        <code>{{ parameter.guid }}</code>
                    </td>
                    <td>
                        {{ parameter.data_type }}
                    </td>
                    <td>
                        {{ parameter.group }}
                    </td>
                    <td>
                        {{ parameter.visible }}
                    </td>
                    <td>
                        {{ parameter.instance }}
                    </td>
                    <td>
                        {{ parameter.disciplines.join(', ') }}
                    </td>
                    <td>
                        {{ parameter.libraries.join(', ') }}
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :has-prev="hasPrev"
            :has-next="hasNext"
            @pagechanged="onPageChange">
        </pagination>
    </div>
</template>

<script>
import ContentLibrarySelect from "@/content/components/ContentLibrarySelect";
import BaseListComponent from "@/core/components/BaseListComponent";
import ClearButton from "@/core/components/ClearButton";
import DisciplineSelect from "@/core/components/DisciplineSelect";
import LimitSelect from "@/core/components/LimitSelect";
import Pagination from "@/core/components/Pagination";
import SearchInput from "@/core/components/SearchInput";
import SectionNav from "@/core/components/SectionNav";
import Parameter from "../models/Parameter";
import { cleanData } from "@/utils.js";
import _ from "lodash";

export default {
    mixins: [BaseListComponent],
    components: {
        ClearButton,
        ContentLibrarySelect,
        DisciplineSelect,
        LimitSelect,
        Pagination,
        SearchInput,
        SectionNav
    },
    data() {
        return {
            filters: {
                q: null,
                guid: null,
                limit: 100,
                offset: 0,
                discipline: null,
                library: null,
            },
            listUrl: `${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/`,
            modelClass: Parameter,
        }
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.discipline)
                filters.discipline = filters.discipline.name;
            if (filters.library)
                filters.library = filters.library.code;

            return filters;
        }
    }
}
</script>
