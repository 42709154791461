<template>
    <div>
        <autocomplete v-model="selectedGroup"
            :placeholder="placeholder"
            :load-results="loadGroups"
            :initial-value="selectedGroup">
        </autocomplete>
    </div>
</template>
 
<script>
import http from "@/http";

import Autocomplete from "@/core/components/Autocomplete.vue";

export default {
    props: {
        placeholder: String,
        modelValue: String,
    },
    components: { Autocomplete },
    emits: ['update:modelValue'],
    data() {
        return {
            selectedGroup: null,
        }
    },
    created() {
        this.selectedGroup = this.modelValue;
    },
    methods: {
        async loadGroups(q) {
            let groups = await http.get(`${process.env.VUE_APP_API_URL_V1}/bim/shared-parameters/groups/`, { q })
                .then(resp => resp.json());

            // Add the entered text as the first result in the list
            groups.unshift(q);

            return groups;
        },
    },
    watch: {
        selectedGroup(val) {
            this.$emit('update:modelValue', val);
        },
        modelValue(val) {
            this.selectedGroup = val;
        },
    }
}
</script>