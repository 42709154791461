<template>
    <div class="field is-grouped">
        <div class="field">
            <label for="units"
                class="label">Application</label>
            <application-select v-model="options.application"
                :show-all="false"
                id="application">
            </application-select>
        </div>
        <div class="field">
            <label for="contenttype"
                class="label">Content Type</label>
            <div class="control">
                <div class="select"
                    id="contenttype">
                    <select v-model="options.contentType">
                        <option v-for="contentType in filteredContentTypes"
                            :value="contentType">
                            {{ contentType.title }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <label for="units"
                class="label">Units</label>
            <units-select v-model="options.units"
                :show-all="false"
                id="units">
            </units-select>
        </div>
        <div class="field">
            <label for="library"
                class="label">Library</label>
            <content-library-select v-model="options.library"
                :show-none="true"
                id="library">
            </content-library-select>
        </div>
        <div class="field">
            <label for="set"
                class="label">Set</label>
            <sets-autocomplete v-model="options.set"
                placeholder="Search sets"
                id="set">
            </sets-autocomplete>
        </div>
        <div class="field">
            <label for="conflicts"
                class="label">Resolve conflicts</label>
            <div class="control">
                <div class="select">
                    <select v-model="options.conflict"
                        id="conflicts">
                        <option value="R">Rename new item</option>
                        <option value="V">Create version of existing item</option>
                    </select>
                </div>
            </div>
            <div class="help">What to do when an item with same name exists</div>
        </div>
    </div>
    <div class="notification is-warning"
        v-if="message">
        {{ message }}
    </div>
    <file-upload v-if="formValid"
        :allowed-types="options.contentType.extensions"
        :grouper-function="options.contentType.groupFiles"
        :show-filenames="true"
        :show-preview="true"
        :data="uploadData"
        :upload-url="uploadUrl"
        :confirm-on-click="false"
        :success-route="x => getSuccessRoute(x)"
        :instruction="formattedInstruction">
    </file-upload>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect";
import ContentLibrarySelect from "./ContentLibrarySelect";
import FileUpload from "@/core/components/FileUpload";
import PersistentObjectStore from "@/objectStore.js";
import SetsAutocomplete from "./SetsAutocomplete.vue";
import UnitsSelect from "@/core/components/UnitsSelect";
import { CONTENTTYPES } from "@/content/constants";

export default {
    components: {
        ApplicationSelect,
        ContentLibrarySelect,
        FileUpload,
        SetsAutocomplete,
        UnitsSelect,
    },
    data() {
        return {
            options: {
                application: null,
                contentType: CONTENTTYPES[0],
                library: null,
                set: null,
                units: 'I',
                conflict: 'R',
            },
            contentTypes: CONTENTTYPES,
            contentSets: [],
            message: "Select a library to continue",
            saving: false,
            _store: null,
        };
    },
    created() {
        this._store = new PersistentObjectStore(this.$route.href);
        this.loadState();
    },
    computed: {
        formattedInstruction() {
            if (this.options.contentType) {
                return `<b>Drop files here to upload (allowed file types: ${this.options.contentType.extensions.join(', ')})</b> <br>Up to 20 files at a time <br>120MB maximum file size`;
            } else {
                return
            }
        },
        formValid() {
            return this.options.contentType && this.options.library?.code && this.options.units;
        },
        uploadData() {
            let data = {
                content_type: this.options.contentType.code,
                library: this.options.library.code,
                units: this.options.units,
                conflict: this.options.conflict,
            };

            if (this.options.set)
                data.set = this.options.set;

            return data;
        },
        uploadUrl() {
            return `${process.env.VUE_APP_API_URL_V2}/content/upload/`;
        },
        filteredContentTypes() {
            if (this.options.application)
                return this.contentTypes.filter(x => x.application == this.options.application.code);
            else
                return this.contentTypes;
        },
    },
    methods: {
        getSuccessRoute(data) {
            return {
                name: 'content-detail',
                params: {
                    id: data.id
                }
            }
        },
        loadState() {
            this.options = Object.assign(this.options, this._store.get('options'));
        },
        saveState() {
            if (this._store)
                this._store.set('options', this.options);
        },
    },
    watch: {
        options: {
            handler() {
                if (!this.options.library)
                    this.message = "Select a library to continue";
                else
                    this.message = null;

                if (!this.options.contentType || this.options.contentType.application != this.options.application.code)
                    this.options.contentType = this.filteredContentTypes[0];

                this.saveState();
            },
            deep: true,
        },
    }
}
</script>

<style lang="sass">
.label
    font-weight: normal
    margin-bottom: 2px
</style>