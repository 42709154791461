import ContentType from "./models/ContentType";
import ImageAction from "./models/ImageAction";
import { REVIT, AUTOCAD, MICROSTATION, INVENTOR } from "@/constants";


const INITIAL = 'I';
const DRAFT = 'D';
const SUBMITTED = 'S';
const UNDER_REVIEW = 'R';
const PUBLISHED = 'P';
const REJECTED = 'X';

const STATES = [
    { code: INITIAL, title: 'Initial' },
    { code: DRAFT, title: 'Draft' },
    { code: SUBMITTED, title: 'Submitted' },
    { code: UNDER_REVIEW, title: 'Under review' },
    { code: PUBLISHED, title: 'Published' },
    { code: REJECTED, title: 'Rejected' },
]

const REQUEST_STATES = {
    C: "Complete",
    A: "Assigned",
    N: "New",
    R: "Rejected",
}

const TRANSITIONS = {
    managed: {
        I: [DRAFT],
        D: [INITIAL, SUBMITTED],
        S: [UNDER_REVIEW],
        R: [PUBLISHED, REJECTED],
        P: [REJECTED],
        X: [DRAFT],
    },
    unManaged: {
        I: [PUBLISHED],
        P: [REJECTED],
        X: [PUBLISHED],
        D: [PUBLISHED],
        S: [PUBLISHED],
        R: [PUBLISHED],
    },
}
const FAMILY = 'FA';
const REVIT_TEMPLATE = 'RT';
const REVIT_HOSTED_CONTENT = 'HC';
const FAMILY_TEMPLATE = 'FT';
const AUTOCAD_BLOCK = 'AB';
const INVENTOR_PART = 'IP';
const INVENTOR_ASSEMBLY = 'IA';
const MICROSTATION_CELL = 'CL';
const CIVIL3D_SUBASSEMBLY = 'CS';

const CIVIL3D_GRAVITY_PIPE_PART = 'CP';
const CIVIL3D_PRESSURE_PART = 'CR';
const CIVIL3D_GRAVITY_STRUCTURE_PART = 'CT';

const IPGrouper = (files) => {
    let filesMap = {};
    files.forEach(x => {
        let fileInfo = splitFilepath(x.name);
        if (fileInfo.basename.endsWith('_dim'))
            fileInfo.basename = fileInfo.basename.split(/_dim$/)[0];
        if (fileInfo.basename.endsWith('_200'))
            return;
        if (filesMap[fileInfo.basename] == undefined)
            filesMap[fileInfo.basename] = {};
        filesMap[fileInfo.basename][fileInfo.ext] = x;
    });
    return filesMap;
}

const IAGrouper = (files) => {
    return { inventor: files }
}

const IARemove = (files) => {
    return files.filter(x => x.name.endsWith('_200.jpg'));
}

const CONTENTTYPES = [
    new ContentType(AUTOCAD, AUTOCAD_BLOCK, 'AutoCAD Block', ['dwg']),
    new ContentType(AUTOCAD, CIVIL3D_GRAVITY_PIPE_PART, 'Civil3D Gravity Pipe Part', ['zip']),
    new ContentType(AUTOCAD, CIVIL3D_GRAVITY_STRUCTURE_PART, 'Civil3D Gravity Structure Part', ['zip']),
    new ContentType(AUTOCAD, CIVIL3D_PRESSURE_PART, 'Civil3D Pressure Part', ['zip']),
    new ContentType(AUTOCAD, CIVIL3D_SUBASSEMBLY, 'Civil3D Subassembly', ['pkt']),
    new ContentType(INVENTOR, INVENTOR_ASSEMBLY, 'Inventor Assembly', ['iam', 'ipt', 'xml', 'jpg'], ['iam'], IAGrouper),
    new ContentType(INVENTOR, INVENTOR_PART, 'Inventor Part', ['ipt', 'xml', 'jpg'], ['ipt'], IPGrouper, IARemove),
    new ContentType(MICROSTATION, MICROSTATION_CELL, 'MicroStation Cell', ['cel', 'dgn']),
    new ContentType(REVIT, FAMILY, 'Revit Family', ['rfa', 'txt'], ['rfa']),
    new ContentType(REVIT, FAMILY_TEMPLATE, 'Revit Family Template', ['rft']),
    new ContentType(REVIT, REVIT_HOSTED_CONTENT, 'Revit Hosted Content', ['rvt']),
    new ContentType(REVIT, REVIT_TEMPLATE, 'Revit Template', ['rvt', 'rte']),
];

const ASSOCIATED_FILETYPES = {  
    TC: 'Revit Type Catalog',
    IP: 'Inventor Part XML',
    CP: 'Civil3D Part XML',
    CS: 'Cutsheet',
    GE: 'General',
}

const ACTIONS = [
    new ImageAction('AUTO_CONTRAST'),
    new ImageAction('AUTO_CROP'),
    new ImageAction('EQUALIZE'),
    new ImageAction('GRAYSCALE'),
    new ImageAction('INVERT'),
    new ImageAction('MAKE_DEFAULT', (image, content) => {
        content.images.forEach(image => {
            if (image.name == AUTO_NAME)
                image.name = '';
        })
        image.name = AUTO_NAME;
    }),
    new ImageAction('MONOCHROME'),
    new ImageAction('POSTERIZE'),
    new ImageAction('REMOVE_BACKGROUND'),
]

const DELETE_ACTION = new ImageAction('DELETE', (image, content) => {
    content.images = content.images.filter(x => x.id != image.id);
});

export {
    ACTIONS,
    ASSOCIATED_FILETYPES,
    CONTENTTYPES,
    DELETE_ACTION,
    REQUEST_STATES,
    STATES,
    TRANSITIONS,
};