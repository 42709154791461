<template>
    <div v-if="item">
        <div class="is-pulled-right">
            <section-nav :routes="sectionRoutes"/>
        </div>
        <h2 class="title">{{ item.filename }}</h2>
        <div class="columns">
            <div class="column is-half">
                <div class="panel">
                    <div class="panel-heading">
                        <router-link class="is-pulled-right"
                            title="Edit details"
                            :to="{ name: 'model-edit', params: { id: this.$route.params.id } }">
                            <span class="mdi mdi-pencil"></span>
                        </router-link>Details
                    </div>
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Active:</th>
                                <td>{{ item.active ? 'True' : 'False' }}</td>
                            </tr>
                            <tr>
                                <th>Application:</th>
                                <td>{{ item.applicationName }}</td>
                            </tr>
                            <tr>
                                <th>Discipline:</th>
                                <td>{{ item.discipline }}</td>
                            </tr>
                            <tr>
                                <th>Project:</th>
                                <td>
                                    <router-link v-if="item.project?.id"
                                        :to="{ name: 'project-detail', params: { id: item.project?.id } }">
                                        {{ item.project }}
                                    </router-link>
                                    <span v-else>{{ item.project }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Filename:</th>
                                <td>{{ item.filename }}</td>
                            </tr>
                            <tr>
                                <th>Cloud ID:</th>
                                <td>{{ item.bim360Id }}</td>
                            </tr>
                            <tr>
                                <th>Cloud Project ID:</th>
                                <td>{{ item.bim360ProjectId }}</td>
                            </tr>
                            <tr>
                                <th>Version:</th>
                                <td>
                                    {{ item.version }}
                                </td>
                            </tr>
                            <tr>
                                <th>Units:</th>
                                <td>{{ item.unitsName }}</td>
                            </tr>
                            <tr>
                                <th>File Size:</th>
                                <td>
                                    <template v-if="item.filesize">
                                        {{ (item.filesize / 1024 / 1024).toLocaleString() }} MB
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <th>Elements:</th>
                                <td>
                                    <template v-if="item.elements">{{ item.elements.toLocaleString() }}</template>
                                </td>
                            </tr>
                            <tr>
                                <th>Last Model Information Update:</th>
                                <td>{{ item.lastModelInformationUpdate?.fromNow() }}</td>
                            </tr>
                            <template v-if="item.application == 'R'">
                                <tr>
                                    <th>Project base point:</th>
                                    <td>
                                        {{ formatPoint(item.projectBasePoint) }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Survey base point:</th>
                                    <td>{{ formatPoint(item.surveyBasePoint) }}</td>
                                </tr>
                                <tr>
                                    <th>Views:</th>
                                    <td>{{ item.views?.toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Sheets:</th>
                                    <td>{{ item.sheets?.toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Groups:</th>
                                    <td>{{ item.groups?.toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Warnings:</th>
                                    <td>{{ item.warnings?.toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Sync Average:</th>
                                    <td>{{ item.syncDurationAvg }}</td>
                                </tr>
                                <tr>
                                    <th>Open Average:</th>
                                    <td>{{ item.openDurationAvg }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="box">
            <h4 class="is-size-5">By day</h4>
            <model-data :model="item"
                period="day"></model-data>
        </div>
        <div class="columns">
            <div class="column">
                <div class="box">
                    <h4 class="is-size-5">By month</h4>
                    <model-data :model="item"
                        period="month"></model-data>
                </div>
            </div>
            <div class="column">
                <div class="box">
                    <h4 class="is-size-5">By hour</h4>
                    <model-data :model="item"
                        period="hour"></model-data>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Model from "../models/Model";
import SectionNav from "@/core/components/SectionNav";
import ModelData from "./ModelData";
import BaseDetailComponent from "@/core/components/BaseDetailComponent";

export default {
    mixins: [BaseDetailComponent],
    components: {
        SectionNav,
        ModelData,
    },
    data() {
        return {
            sectionRoutes: [],
            detailUrl: `${process.env.VUE_APP_API_URL_V1}/bim/models/${this.$route.params.id}/`,
            modelClass: Model,
        };
    },
    methods: {
        async setup() {
            await this.item.load();

            this.emitter.emit("breadcrumbs", [
                { route: 'home', name: 'Home' },
                { route: 'model-list', name: `${this.item.project.number} models`, query: { project: this.item.project.id } },
                { route: 'model-detail', name: this.item.filename, params: { id: this.item.id } },
            ]);

            this.sectionRoutes = [
                { name: 'project-detail', params: { id: this.item.project.id } },
                {
                    name: 'audit-element-list',
                    query: {
                        model: this.item.id,
                        project: this.item.project.id,
                        application: this.item.application
                    }
                },
                { name: 'journal-list', query: { model: this.item.id } },
                { name: 'model-events', query: { model: this.item.id } },
            ]
        },
        formatPoint(point) {
            if (!point)
                return point;
            else
                return point.join(', ');
        }
    },
};
</script>