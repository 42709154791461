<template>
    <section-nav :names="['stats-tools', 'stats-content']"
        class="is-pulled-right"></section-nav>
    <h2 class="title"> {{ pageTitle }} Events</h2>
    <div class="field is-grouped is-grouped-multiline">
        <div class="control">
            <div class="select">
                <select v-model="filters.event">
                    <option :value="null">
                        All types
                    </option>
                    <option v-for="eventType in eventTypes">
                        {{ eventType }}
                    </option>
                </select>
            </div>
        </div>
        <div class="control"
            v-if="filters.event">
            <div class="select">
                <select class="form-control"
                    v-model="filters.type">
                    <option :value="null">
                        All {{ filters.event }} sub types
                    </option>
                    <option v-for="subtype in subtypeOptions">{{ subtype }}</option>
                </select>
            </div>
        </div>
        <application-select v-model="filters.application"
            :show-all="true">
        </application-select>
        <div class="control">
            <div class="field is-grouped">
                <label class="label p-2">From:</label>
                <div class="control">
                    <input class="input"
                        type="date"
                        v-model="filters.start">
                </div>
                <label class="label p-2">To:</label>
                <div class="control">
                    <input class="input"
                        type="date"
                        v-model="filters.end"
                        :max="maxDate">
                </div>
            </div>
        </div>
        <div class="control">
            <label class="checkbox p-3">
                <input type="checkbox"
                    v-model="fuzzyDate">
                Show fuzzy dates
            </label>
        </div>
        <div class="control">
            <div class="dropdown is-hoverable"
                v-if="filters.event && dataFields.length > 0">
                <div class="dropdown-trigger">
                    <button class="button"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu">
                        <span>Show / hide fields</span>
                    </button>
                </div>
                <div class="dropdown-menu"
                    id="dropdown-menu"
                    role="menu">
                    <div class="dropdown-content">
                        <a v-for="field in dataFields"
                            :key="field"
                            @click="toggleField(field)"
                            class="dropdown-item">
                            <span class="icon">
                                <span v-if="hiddenFields.includes(field)"
                                    class="mdi mdi-close"></span>
                                <span v-else
                                    class="mdi mdi-check"></span>
                            </span>
                            <span>{{ cleanedField(field) }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <limit-select v-model="filters.limit" />
        <clear-button v-if="isFiltered"
            @click="clearFilters">
        </clear-button>
    </div>
    <div class="field is-grouped is-grouped-multiline">
        <user-autocomplete v-model="filters.user"
            :placeholder="'Search users'">
        </user-autocomplete>
        <project-autocomplete v-model="filters.project"
            :placeholder="'Search projects'">
        </project-autocomplete>
    </div>
    <table class="table is-fullwidth is-hoverable mt-3">
        <thead>
            <tr>
                <th v-if="!filters.event">Type</th>
                <th>Sub Type</th>
                <th>Application</th>
                <th>Description</th>
                <th>Date</th>
                <th>Model</th>
                <th>Project</th>
                <th>User</th>
                <th v-for="field in visibleDataFields"
                    class="nowrap">{{ cleanedField(field) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="event in items">
                <td v-if="!filters.event">
                    <a @click="setFilter('event', event.event)">{{ event.event }}</a>
                </td>
                <td>
                    <a @click="setFilter('type', event.type); setFilter('event', event.event)">
                        {{ event.typeDescription }}
                    </a>
                </td>
                <td>{{ event.application }}</td>
                <td>{{ event.description }}</td>
                <td>
                    <span v-if="fuzzyDate">
                        {{ event.date.fromNow() }}
                    </span>
                    <span v-else>
                        {{ event.date.format('MMM D, YYYY, h:mm A') }}
                    </span>
                </td>
                <td>
                    <a v-if="event.model.name">
                        {{ event.model.name }}
                    </a>
                    <span v-else>-</span>
                </td>
                <td>
                    <a @click="setFilter('project', event.project)"
                        v-if="event.project.name">
                        {{ event.project.name }}
                    </a>
                    <span v-else>-</span>
                </td>
                <td>
                    <a @click="setFilter('user', event.user)"
                        v-if="event.user.name">
                        {{ event.user.name }}
                    </a>
                    <span v-else>-</span>
                </td>
                <td v-for="field in visibleDataFields">
                    <span v-html="formatDataField(event, field)"></span>
                </td>
            </tr>
        </tbody>
    </table>
    <pagination :has-prev="hasPrev"
        :has-next="hasNext"
        @pagechanged="onPageChange">
    </pagination>
</template>

<script>
import ApplicationSelect from "@/core/components/ApplicationSelect.vue";
import BaseListComponent from "@/core/components/BaseListComponent.js";
import ClearButton from '@/core/components/ClearButton.vue';
import LimitSelect from "@/core/components/LimitSelect.vue";
import Pagination from "@/core/components/Pagination.vue";
import SectionNav from "@/core/components/SectionNav";
import http from "@/http";
import ProjectAutocomplete from "@/projects/components/ProjectAutocomplete.vue";
import DateRange from "@/stats/components/DateRange.vue";
import { DATA_FIELD_FORMATTERS, TYPES } from "@/stats/constants.js";
import UserAutocomplete from "@/users/components/UserAutocomplete.vue";
import { cleanData } from "@/utils";
import _ from "lodash";
import moment from "moment";
import StatsEvent from "../models/StatsEvent.js";

export default {
    mixins: [BaseListComponent],
    components: {
        ApplicationSelect,
        ClearButton,
        DateRange,
        LimitSelect,
        Pagination,
        ProjectAutocomplete,
        SectionNav,
        UserAutocomplete,
    },
    data() {
        return {
            filters: {
                application: null,
                event: null,
                project: null,
                type: null,
                user: null,
                start: moment().subtract(100, 'days').format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
                limit: null,
                offset: 0,
            },
            resultsCount: 0,
            events: [],
            types: [],
            eventTypes: [],
            hiddenFields: [],
            fuzzyDate: true,
        };
    },
    async created() {
        this.eventTypes = Object.keys(TYPES).filter(x => x != 'Revit');
        await this.loadItems();
    },
    methods: {
        async loadUrlArgs() {
            let projectId = this.$route.query.project;
            if (!projectId)
                return;

            await this.$store.dispatch('projects/loadProjectById', projectId);
            this.filters.project = this.$store.getters['projects/getProjectById'](projectId)
        },
        async loadItems() {
            let data = await http.get(`${process.env.VUE_APP_API_URL_V1}/stats/combined/`, this.cleanedFilters)
                .then(resp => resp.json());
            this.resultsCount = data.count;
            this.items = data.results.map(x => new StatsEvent(x));
        },
        toggleField(field) {
            if (this.hiddenFields.includes(field)) {
                this.hiddenFields.splice(this.hiddenFields.indexOf(field), 1);
            } else {
                this.hiddenFields.push(field);
            }
        },
        cleanedField(field) {
            if (typeof field === 'string') {
                return _.startCase(field);
            } else {
                return 'Invalid Field';
            }
        },
        setFilter(key, val) {
            if (key == 'event' && this.filters.event)
                return;

            if (this.filters[key]) {
                if (this.filters[key] == val || this.filters[key].id == val.id)
                    this.filters[key] = null;
            } else {
                this.filters[key] = val;
            }
        },
        formatDataField(event, field) {
            const val = event.data[field];
            return DATA_FIELD_FORMATTERS[event.event][event.type](field, val);
        },
    },
    computed: {
        cleanedFilters() {
            let filters = cleanData(this.filters);

            if (filters.event)
                filters.event = filters.event;
            if (filters.type)
                filters.type = Object.keys(TYPES[filters.event]).find(key => TYPES[filters.event][key] === filters.type);
            if (filters.application)
                filters.application = filters.application.code;
            if (filters.project)
                filters.project = filters.project.id;
            if (filters.user)
                filters.user = filters.user.id;

            return filters;
        },
        toolsMap() {
            const data = (this.$store.getters["stats/getTools"]).reduce((acc, tool) => {
                const subDictionary = {};
                for (const key in tool) {
                    subDictionary[key] = tool[key];
                }
                acc[tool.tool_name] = subDictionary;
                return acc;
            }, {});
            return data;
        },
        dataFields() {
            if (this.filters.event) {
                let keys = [];
                this.events.forEach(event => {
                    Object.keys(event.data).forEach(key => {
                        if (!keys.includes(key))
                            keys.push(key);
                    });
                });
                return keys;
            }
            else
                return [];
        },
        subtypeOptions() {
            if (this.filters.event) {
                return Object.values(TYPES[this.filters.event]);
            }
            return [];
        },
        visibleDataFields() {
            return this.dataFields.filter(field => !this.hiddenFields.includes(field));
        },
        maxDate() {
            return moment().format('YYYY-MM-DD');
        },
    },
};
</script>