<template>

    <div v-if="templateName == 'nav'">
        <div v-for="notification in filteredNotifications"
            :class="notification.cssClass">
            <div class="p-3"
                :class="containerClass">
                <button v-if="notification.dismissable"
                    @click="dismiss(notification)"
                    title="Dismiss this notification"
                    class="delete is-pulled-right"></button>
                {{ notification.text }}
            </div>
        </div>
    </div>

    <div v-if="templateName == 'panel'"
        class="panel">
        <div class="panel-heading">Notifications</div>
        <div v-for="notification in filteredNotifications"
            class="panel-block is-block">
            <router-link v-if="notification.data?.id"
                :to="{ name: 'content-detail', params: { id: notification.data.id } }"
                target="_blank">
                {{ notification.data.name }} {{ notification.actionDescription }}
            </router-link>
            <span v-else>{{ notification.text }}</span>
            <span class="is-pulled-right">
                {{ notification.start }}
            </span>
        </div>
    </div>

</template>

<script>
import PersistentObjectStore from "@/objectStore.js";

export default {
    props: {
        containerClass: String,
        templateName: {
            type: String,
            required: true,
        },
        notificationTypes: {
            type: Array,
            required: true,
        },
        library: Object,
        project: Object,
        targets: Array,
    },
    data() {
        return {
            allNotifications: [],
            store: null,
        }
    },
    async mounted() {
        this.store = new PersistentObjectStore("notifications");

        await this.$store.dispatch("core/loadNotifications");
        this.allNotifications = this.$store.getters["core/getNotifications"];
    },
    computed: {
        filteredNotifications() {
            let dismissed = this.store?.get('dismissed') || [];

            let notifications = [...this.allNotifications];

            if (this.targets)
                notifications = notifications.filter(x => {
                    return x.targets.forEach(y => this.targets.includes(y))
                });

            if (this.library)
                notifications = notifications.filter(x => x.library == this.library.id);

            if (this.project)
                notifications = notifications.filter(x => x.projects.map(y => y.id).includes(this.project.id));

            if (this.notificationTypes)
                notifications = notifications.filter(x => this.notificationTypes.includes(x.type));

            return notifications.filter(x => !dismissed.includes(x.id));
        },
    },
    methods: {
        dismiss(notification) {
            let dismissed = this.store.get('dismissed') || [];
            if (dismissed.includes(notification.id))
                return;

            dismissed.push(notification.id)
            this.store.set('dismissed', dismissed);
        }
    }
}
</script>
